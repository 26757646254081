import { createAsyncThunk } from '@reduxjs/toolkit';

import { createWsAction } from 'src/store/utils';
import type { FormStrikePrice, StrikePrice } from 'src/store/loans/strike-prices/types';
import { StrikePricesCommands } from 'src/store/loans/strike-prices/types';

export type SetFormStrikePricesPayload = FormStrikePrice[];

type AddStrikePricePayload = Omit<StrikePrice, 'id'> & { loanID?: string };

export const addStrikePrice = createWsAction<AddStrikePricePayload, AddStrikePricePayload>(
  'loans/addStrikePrice',
  (data) => ({
    command: StrikePricesCommands.AddStrikePrice,
    ...data,
  }),
);

export function mapFormStrikePrice(formStrikePrice: FormStrikePrice): Omit<StrikePrice, 'id'> {
  const { tokensAmount, strikePrice, strikeDate, endDate, strikeType, ...rest } = formStrikePrice;
  return {
    tokensAmount: Number(tokensAmount),
    strikePrice: Number(strikePrice),
    strikeDate: strikeDate?.format('YYYY-MM-DD') ?? '',
    endDate: endDate?.format('YYYY-MM-DD') ?? '',
    strikeType: Number(strikeType) as StrikePrice['strikeType'],
    ...rest,
  };
}

interface BulkAddStrikePricesPayload {
  strikePrices: FormStrikePrice[] | Omit<StrikePrice, 'id'>[];
  loanID?: string;
}

export const bulkAddStrikePrices = createAsyncThunk(
  'loans/bulkAddStrikePrice',
  async (data: BulkAddStrikePricesPayload, thunkApi) => {
    const { loanID, strikePrices } = data;
    const jsonStrikePrices =
      typeof strikePrices[0].strikeDate === 'string'
        ? (strikePrices as StrikePrice[])
        : (strikePrices as FormStrikePrice[]).map(mapFormStrikePrice);
    await Promise.all(
      jsonStrikePrices.map((item) => thunkApi.dispatch(addStrikePrice({ ...item, loanID }))),
    );
  },
);

type EditStrikePricePayload = FormStrikePrice & { id: number };

export const editStrikePrice = createWsAction<EditStrikePricePayload, StrikePrice>(
  'loans/editStrikePrice',
  (data) => {
    const { id, ...rest } = data;
    const strikePrice = mapFormStrikePrice(rest);
    return {
      command: StrikePricesCommands.EditStrikePrice,
      id,
      ...strikePrice,
    };
  },
);

export const removeStrikePrice = createWsAction<number, { id: number }>(
  'loans/removeStrikePrice',
  (id) => ({
    command: StrikePricesCommands.RemoveStrikePrice,
    id,
  }),
);
