import { createSlice } from '@reduxjs/toolkit';
// import { PURGE } from "redux-persist";

export type AccountCopyField = 'name' | 'label';
export interface PreferencesState {
  error: null | {
    title: string;
    description?: string;
  };

  locale: string;
  accountCopyField: AccountCopyField;
}

const initialState: PreferencesState = {
  error: null,

  locale: 'en-GB',
  accountCopyField: 'label',
};

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setAccountCopyField(state, action: { payload: { value: AccountCopyField } }) {
      console.debug('setAccountCopyField action:', action);
      state.accountCopyField = action.payload.value;
    },
  },
  // extraReducers: (builder) => {},
});

export const preferencesActions = { ...preferencesSlice.actions };
// export const { toggleSidebar, openNewpreferencesModal, closeNewpreferencesModal } = preferencesSlice.actions;

export default preferencesSlice.reducer;
