import { ColdWalletsEndpoints } from 'src/store/cold-wallets/rtkColdWalletsApi/types';
import { rtkColdWalletApi } from 'src/store/cold-wallets/rtkColdWalletsApi';
import type {
  WalletData,
  WalletAdress,
  BodyLinkColdWallet,
  BaseWalletData,
  WalletListData,
  BodyUpdateColdWallet,
  WalletSnapshot,
  AllProjectWalletSnapshot,
  GetAllWalletsSnapshotParams,
} from 'src/store/cold-wallets/types';
import qs from 'qs';

export const walletsApi = rtkColdWalletApi.injectEndpoints({
  endpoints: (build) => ({
    getColdWallets: build.query<WalletListData, number>({
      query: (projectId) => ({
        url: ColdWalletsEndpoints.GetColdWallets.replace(':projectId', `${projectId}`),
        method: 'GET',
      }),
      providesTags: ['LoanColdWallets'],
    }),
    getFormatAddress: build.query<WalletAdress, string>({
      query: (address) => ({
        url: ColdWalletsEndpoints.ColdWalletAddress.replace(':address', address),
        method: 'GET',
      }),
    }),
    getWalletSnapshot: build.query<WalletSnapshot, number>({
      query: (walletId) => ({
        url: ColdWalletsEndpoints.GetWalletSnapshot.replace(':walletId', `${walletId}`),
        method: 'GET',
      }),
    }),
    getWalletProjectSnapshot: build.query<AllProjectWalletSnapshot, GetAllWalletsSnapshotParams>({
      query: ({ projectId, startDate, endDate }) => {
        const params = qs.stringify({ startDate, endDate }, { skipNulls: true });

        return {
          url: `${ColdWalletsEndpoints.GetAllWalletsSnapshot.replace(':projectId', `${projectId}`)}?${params}`,
          method: 'GET',
        };
      },
    }),
    postLinkColdWallet: build.mutation<WalletData, BodyLinkColdWallet>({
      query: (body) => ({
        url: ColdWalletsEndpoints.LinkColdWallet,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LoanColdWallets'],
    }),
    postUnlinkColdWallet: build.mutation<BaseWalletData, { id: number }>({
      query: (body) => ({
        url: ColdWalletsEndpoints.UnlinkColdWallet,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LoanColdWallets'],
    }),
    putUpdateColdWallet: build.mutation<
      WalletListData,
      { walletId: number; body: BodyUpdateColdWallet }
    >({
      query: ({ walletId, body }) => ({
        url: ColdWalletsEndpoints.UpdateColdWallet.replace(':walletId', `${walletId}`),
        method: 'PUT',
        body,
      }),
      onQueryStarted: async ({ walletId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          await dispatch(walletsApi.util.upsertQueryData('getColdWallets', walletId, data));
        } catch (error) {
          console.error('error:', error);
        }
      },
      invalidatesTags: ['LoanColdWallets'],
    }),
    postAddWalletSnapshot: build.mutation<WalletSnapshot, { walletIds: number[] }>({
      query: ({ walletIds }) => ({
        url: ColdWalletsEndpoints.AddWalletSnapshot,
        method: 'POST',
        body: { walletIds },
      }),
    }),
  }),
});

export const {
  useGetFormatAddressQuery,
  useGetColdWalletsQuery,
  useGetWalletSnapshotQuery,
  useGetWalletProjectSnapshotQuery,
  useLazyGetFormatAddressQuery,
  usePostUnlinkColdWalletMutation,
  usePostLinkColdWalletMutation,
  usePutUpdateColdWalletMutation,
  usePostAddWalletSnapshotMutation,
} = walletsApi;
