import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { wsLoansSend } from 'src/store/ws-loans/actions';
import type {
  LinkProjectPayload,
  DeleteApiKeyPayload,
  CreateSubAccountPayload,
  CreateApiKeyPayload,
  UpdateApiKeyPayload,
  ExchangeObjItems,
} from 'src/store/apiKeys/types';
import { AdminApiKeyEndpoints, ApiCommands } from 'src/store/apiKeys/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_URL, ADMIN_X_TOKEN } from 'src/constants';

export interface ListenApiKeysPayload {
  section: 'api';
  isInitCall: boolean;
}

export const listen = createAsyncThunk('apiKeys/listen', (data: ListenApiKeysPayload, thunkApi) =>
  thunkApi.dispatch(
    wsLoansSend({
      command: 'Listen',
      ...omit(data, 'isInitCall'),
    }),
  ),
);

export const encryptedToken = createAsyncThunk(
  'apiKeys/encryptedToken',
  (data: { accountID: string }, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.GetAPIKey,
        ...data,
      }),
    ),
);

export const createApiKey = createAsyncThunk(
  'apiKeys/createApiKey',
  (data: CreateApiKeyPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.CreateAPIKey,
        ...data,
      }),
    ),
);

export const updateLabelApiKey = createAsyncThunk(
  'apiKeys/updateLabel',
  (data: UpdateApiKeyPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.UpdateAPIKey,
        ...data,
      }),
    ),
);

export const linkApiKeyToProject = createAsyncThunk(
  'apiKeys/linkApiKeyToProject',
  (data: LinkProjectPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.LinkLoan,
        ...data,
      }),
    ),
);

export const unlinkApiKeyFromProject = createAsyncThunk(
  'apiKeys/unlinkApiKeyFromProject',
  (data: LinkProjectPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.UnlinkLoan,
        ...data,
      }),
    ),
);

export const createSubAccount = createAsyncThunk(
  'apiKeys/createSubAccount',
  (data: CreateSubAccountPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.CreateSubAccount,
        ...data,
      }),
    ),
);

export const deleteApiKey = createAsyncThunk(
  'apiKeys/deleteApiKey',
  (data: DeleteApiKeyPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.DeleteAPIKey,
        ...data,
      }),
    ),
);

export const rtkApiKey = createApi({
  reducerPath: 'apiKeys/rtk',
  baseQuery: fetchBaseQuery({
    baseUrl: ADMIN_URL,
    prepareHeaders: (headers) => {
      headers.set('x-access-token', ADMIN_X_TOKEN);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getExchange: build.mutation<ExchangeObjItems, void>({
      query: () => ({
        url: AdminApiKeyEndpoints.Exchange,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetExchangeMutation } = rtkApiKey;
