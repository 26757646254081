import { isAnyOf } from '@reduxjs/toolkit';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { initThresholdSlice } from 'src/store/shared/threshold/factory';
import { registerErrorMessage } from 'src/store/utils/errors';
import type { JsonFailure } from 'src/store/types';
import type { JsonAccountThreshold } from '../../shared/threshold/types';

export enum LoanAccountThresholdCommands {
  AccountThresholds = 'AccountThresholds',
  UpsertAccountThreshold = 'UpsertAccountThreshold',
  DeleteAccountThreshold = 'DeleteAccountThreshold',
}

registerErrorMessage(LoanAccountThresholdCommands.DeleteAccountThreshold, {
  title: 'Delete Account Threshold Error',
  getDescription: (json: JsonFailure) => `${json.message}`,
});
registerErrorMessage(LoanAccountThresholdCommands.UpsertAccountThreshold, {
  title: 'Add Account Threshold Error',
  getDescription: (json: JsonFailure) => `${json.message}`,
});

const loanAccountsThresholdSlice = initThresholdSlice({
  prefix: 'loans',
  sliceName: 'threshold',
  commands: LoanAccountThresholdCommands,
});

startListening({
  matcher: isAnyOf(
    loanAccountsThresholdSlice.actions.setAccountThreshold,
    loanAccountsThresholdSlice.actions.deleteAccountThreshold,
  ),
  effect: (action, api) => {
    const { /* command, */ ...thresholdData } = action.payload as JsonAccountThreshold & {
      command: string;
    };
    api.dispatch(loanAccountsThresholdSlice.actions.setThresholdUpdateData(thresholdData));
  },
});

export const loanAccountsThresholdActions = loanAccountsThresholdSlice.actions;
export default loanAccountsThresholdSlice.reducer;
