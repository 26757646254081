import { createSelector } from '@reduxjs/toolkit';
import type { SelectItem } from 'src/store/types';
import type { JsonExchange, ExchangesState } from 'src/store/shared/exchanges/types';

interface Store {
  exchanges: ExchangesState;
}

export const selectIsExchangesLoading = (store: Store) =>
  store.exchanges.requestStatus === 'pending';

export const selectExchanges = (store: Store) => store.exchanges.list;
export const selectExchangeById = (id: number) => (store: Store) =>
  id ? store.exchanges.list.find((exchange) => exchange.id === id) : undefined;

// exchange options/suggestions for autocomplete in NewLoanModal form
export const selectExchangeOptions = createSelector(selectExchanges, (items: JsonExchange[]) => {
  const exchangeOptions: SelectItem[] = items.map((exchange: JsonExchange) => ({
    value: exchange.id, // not .id for some reasons
    label: exchange.name,
  }));

  return exchangeOptions;
});
