import type { RootState } from 'src/store';
import { createSelector } from '@reduxjs/toolkit';

import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import map from 'lodash/map';

const selectorTradingGroupList = (state: RootState) => state.trading.groupList;
const selectorTradingSessionCreateStatus = (state: RootState) => state.trading.sessionCreateStatus;
export const selectorTradingSessionDetails = (state: RootState) => state.trading.sessionDetails;
export const selectorTradingGroupListIsPending = (state: RootState) =>
  state.trading.groupListStatus === 'pending';
export const selectorTradingMessageStatus = (state: RootState) => state.trading.messageStatus;

export const selectorTradingGroupOptions = createSelector(selectorTradingGroupList, (groupList) =>
  map(groupList, ({ id, name }) => ({ value: id, label: name })),
);

export const selectorIsSessionActive = createSelector(
  selectorTradingSessionCreateStatus,
  selectorTradingSessionDetails,
  (status, sessionDetails) => status === 'success' && sessionDetails !== null,
);

export const selectorTradingMessageLog = (state: RootState) => state.trading.messageLog;

export const selectorTradingMessages = createSelector(
  selectorTradingMessageLog,
  selectorTradingSessionDetails,
  (messageLog, sessionDetails) => {
    const sessionMessages = messageLog.filter(
      (message) => message.session === sessionDetails?.session,
    );
    return sessionMessages.map(({ message }) => message).join('\n');
  },
);

export const selectorTradingLastMessageId = createSelector(
  (state: RootState) => state.trading.messageLog,
  (messageLog) => {
    const lastMessage = last(sortBy(messageLog, 'messageID'));
    return lastMessage ? lastMessage.messageID : 0;
  },
);
