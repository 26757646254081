import type { DepthDataMap, JsonLinechartOverview } from 'src/store/loans/types';
import type { MarketVolumesItem, TradingVolumeItem } from 'src/store/loans/exchanges/types';
import type { JsonBalance } from 'src/store/shared/balances/types';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type { LiquidityMap } from 'src/store/shared/kpi/types';

export enum RetainerCommands {
  Listen = 'Listen',
  RetainerOverview = 'RetainerOverview',
  HideRetainer = 'HideRetainer',
  // SetInitialBalance = 'SetInitialBalance',
}

export interface JsonRetainersExchange {
  exchangeID: number;
  pair: string;
  lastPrice: number;
  averageSpread: number;
  ourSpread: number;
  tradingVolumes: TradingVolumeItem[];
  ourLiquidity: LiquidityMap;
  marketVolumes: MarketVolumesItem[];
}

export interface AssetInfo {
  token: string;
  volume: number;
  lastPrice: number;
}

export interface JsonRetainersExchangeExtended extends JsonRetainersExchange {
  currentLiquidityPlus2: number;
  currentLiquidityPlus5: number;
  currentLiquidityPlus10: number;
  currentLiquidityMinus2: number;
  currentLiquidityMinus5: number;
  currentLiquidityMinus10: number;

  avgLiquidityPlus2ByDate: [string, number][];
  avgLiquidityPlus5ByDate: [string, number][];
  avgLiquidityPlus10ByDate: [string, number][];
  avgLiquidityMinus2ByDate: [string, number][];
  avgLiquidityMinus5ByDate: [string, number][];
  avgLiquidityMinus10ByDate: [string, number][];

  volumeShare: number;

  baseToken: AssetInfo;
  otherTokens: AssetInfo[];

  stables: number;
  exchange: JsonExchange;
  depthData: DepthDataMap;
}

export interface JsonRetainersExchangesSuccess {
  command: RetainerCommands.RetainerOverview;
  exchanges: JsonRetainersExchange[];
  balances: JsonBalance[];
  linechartWeek: JsonLinechartOverview;
  linechartYear: JsonLinechartOverview;
}

export interface JsonRetainersStats {
  baseTokenTotal: number;
  otherTokensTotal: number;
  lastPriceTotal: number;
  lastPriceCount: number;
  baseTokenTotalVolume: number;
  baseToken: string;
}

export interface JsonRetainersPiechartData {
  id: string;
  label: string;
  value: number;
  percentageValue: number;
  color: string;
}

export interface RetainersSunburstData {
  baseToken: AssetInfo;
  otherTokens: AssetInfo[];
  id: string;
  exchange: string;
}
