import { jwtDecode } from 'jwt-decode';
import type { FetchArgs } from '@reduxjs/toolkit/query/react';
import isString from 'lodash/isString';
import type { RootState } from 'src/store';
import FingerprintJs from '@fingerprintjs/fingerprintjs';
import type { JwtPayload } from './types';
import { AuthApiEndpoints } from './types';

const noReauthPaths = [
  AuthApiEndpoints.Login,
  AuthApiEndpoints.ResetPassword,
  AuthApiEndpoints.Register,
  AuthApiEndpoints.AuthenticateMfa,
  // AuthApiEndpoints.Logout,
];

const decodeToken = (token?: string): JwtPayload | null => {
  if (!token) {
    return null;
  }

  try {
    return jwtDecode<JwtPayload>(token);
  } catch (error) {
    console.error('Failed to decode token:', error);
    return null;
  }
};

export const getTokenPayload = (token?: string) => {
  const decodedPayload = decodeToken(token);

  const defaultPayload: JwtPayload = {
    sub: '',
    iss: '',
    isMfaAuthenticated: false,
    isMfaEnabled: false,
    isEmailConfirmed: false,
    iat: '',
    exp: '',
  };

  if (!decodedPayload) {
    return { ...defaultPayload, isTokenExpired: true };
  }

  const currentTime = Date.now() / 1000;
  const isTokenExpired = !decodedPayload.exp || +decodedPayload.exp < currentTime;

  return {
    ...decodedPayload,
    isTokenExpired,
  };
};

export function shouldSkipReauth(args: string | FetchArgs) {
  const path = isString(args) ? args : args.url;
  return noReauthPaths.some((noAuthPath) => path === noAuthPath.toString());
}

export async function customHeaders(
  originalHeaders: Headers,
  getState: () => RootState,
): Promise<Headers> {
  const { accessToken } = getState().signup;

  const fp = await FingerprintJs.load();
  const { visitorId: fingerprint } = await fp.get();

  const headers = new Headers(originalHeaders);

  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  if (fingerprint) {
    headers.set('x-fingerprint', fingerprint);
  }

  return headers;
}
