import type { RootState } from 'src/store';
import { createSelector } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import dayjs from 'src/utils/formatting/dates';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';

export const selectTransactions = (state: RootState) => state.transactions.list;

export const selectTransactionsDateRange = createSelector(selectTransactions, (transactions) => {
  if (transactions.length === 0) {
    return [null, null];
  }

  const sorted = orderBy(transactions, 'date', 'asc');

  return [dayjs(sorted[0].date), dayjs(sorted[sorted.length - 1].date)];
});

export const selectTransactionsAllAccounts = createSelector(selectTransactions, (transactions) =>
  reduce(
    uniqBy(transactions, 'accountName'),
    (acc: string[], item) => {
      acc.push(item.accountName);
      return acc;
    },
    [],
  ),
);

export const selectIsTransactionsLoading = (state: RootState) =>
  state.transactions.requestStatus === 'pending';

export const selectIsManualTransactionModalOpen = (store: RootState) =>
  store.transactions.isManualTransactionModalOpen;

export const selsectManualTransactionInitialValues = (store: RootState) =>
  store.transactions.formManualTransaction;

export const selectManualTransactionMeta = (store: RootState) =>
  store.transactions.metaManualTransaction;

export const selectIsManualTransactionSubmitting = (store: RootState) =>
  store.transactions.resManualTransaction === 'pending';

export const selectIsManualTransactionSubmitted = (store: RootState) =>
  store.transactions.resManualTransaction === 'success';

export const selectTransactionsOrder = (store: RootState) => store.transactions.viewSortByOrder;
export const selectTransactionsType = (store: RootState) => store.transactions.viewFilterByType;
