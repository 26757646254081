import { initKpiSlice } from 'src/store/shared/kpi/factory';
import { KpiCommands } from 'src/store/shared/kpi/types';

export enum LoanKpiCommands {
  KPI = 'LoanKPI',
}

const { reducer, actions } = initKpiSlice({
  prefix: 'loans',
  sliceName: 'loansKpi',
  commands: { ...KpiCommands, ...LoanKpiCommands },
});

export const loanKpiActions = actions;
export default reducer;
