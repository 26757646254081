import { createWsAction } from 'src/store/utils';
import { AccountCommands } from './types';

interface EnableAccountPayload {
  accountID: string;
  exchangeID?: number;
}

export const enableAccount = createWsAction<EnableAccountPayload, EnableAccountPayload>(
  'accounts/enableAccount',
  (data) => ({
    command: AccountCommands.EnableAccount,
    ...data,
  }),
);

interface DisableAccountPayload {
  accountID: string;
  exchangeID?: number;
}

export const disableAccount = createWsAction<DisableAccountPayload>(
  'accounts/disableAccount',
  AccountCommands.DisableAccount,
);
