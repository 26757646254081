// /import { createSelector } from '@reduxjs/toolkit';
import type { AuthState } from 'src/store/auth/slice';

interface Store {
  auth: AuthState;
}

export const selectUserId = (store: Store) => store.auth.userId;
export const selectUserEmail = (store: Store) => store.auth.email;
export const selectUserName = (store: Store) => store.auth.name;
export const selectLastAccess = (store: Store) => store.auth.lastAccess;
export const selectUserToken = (store: Store) => store.auth.token;
export const selectNotConnected = (store: Store) => store.auth.notConnected;
export const selectError = (store: Store) => store.auth.error;
export const selectResAuth = (store: Store) => store.auth.resAuth;
export const selectRes2FA = (store: Store) => store.auth.res2FA;
export const selectRedirectUrl = (store: Store) => store.auth.redirectUrl;
export const selectAuthDomain = (store: Store) => store.auth.authDomain;

export default null;
