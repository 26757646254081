import { createWsAction } from 'src/store/utils';

import type {
  AddManualTransactionPayload,
  GetTransactionsPayload,
  RemoveManualTransactionPayload,
  UpdateTransactionPayload,
} from './types';
import { TransactionCommands } from './types';

export const getTransactions = createWsAction<GetTransactionsPayload>(
  'loans/getTransactions',
  TransactionCommands.GetTransactions,
);

export const updateTransaction = createWsAction<UpdateTransactionPayload>(
  'loans/updateTransaction',
  TransactionCommands.UpdateTransaction,
);

export const addManualTransaction = createWsAction<
  AddManualTransactionPayload,
  AddManualTransactionPayload
>(
  'shared/AddManualTransaction',
  (
    data = {
      accountID: '',
      token: '',
      amount: 0,
    },
  ) => ({
    command: TransactionCommands.AddTransaction,
    ...data,
  }),
);

export const removeManualTransaction = createWsAction<
  RemoveManualTransactionPayload,
  RemoveManualTransactionPayload
>(
  'shared/RemoveManualTransaction',
  (
    data = {
      accountID: '',
      transactionID: '',
    },
  ) => ({
    command: TransactionCommands.RemoveTransaction,
    ...data,
  }),
);
