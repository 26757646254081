import type { RequestStatus, JsonListenResponse } from 'src/store/types';
import type { IsoDateString } from 'src/utils/formatting/dates';
import type { GenericError } from 'src/store/utils/errors';

export enum ArbitrageCommands {
  Arbitrage = 'Arbitrage',
  ArbitrageDetails = 'ArbitrageDetails',
}
export interface JsonArbitrage {
  id: number;
  pair: string;
  match: string;
  accountsTotal: number;
  accountsActive: number;
  spread: number;
  buyPrice: number;
  sellPrice: number;
  volume24: number;
  volume: number;
  disbalance: number;
  profit: number;
  status: string;
}

export type JsonArbitrageSuccess = JsonListenResponse<
  ArbitrageCommands.Arbitrage,
  {
    list: JsonArbitrage[];
  }
>;

export type JsonArbitrageDetailsSuccess = JsonListenResponse<
  ArbitrageCommands.ArbitrageDetails,
  JsonArbitrageDetailsJson
>;

export interface JsonArbitrageAccount {
  account: string;
  recommendation: string;
  baseAvailable: number;
  baseRecommended: number; // baseAvailable / baseRecommended
  quoteAvailable: number;
  quoteRecommended: number;
  quoteRatio: number;
  volume24: number;
  volume: number;
  lastTradeTime: IsoDateString;
}

export interface JsonArbitrageDetailsJson {
  id: number;
  settings: string;
  totalFeePaid: number;
  Accounts: JsonArbitrageAccount[];
}

export interface JsonArbitrageDetails extends Omit<JsonArbitrageDetailsJson, 'Accounts'> {
  list: JsonArbitrageAccount[];
}

export interface ArbitrageState {
  list: JsonArbitrage[];
  details: Record<JsonArbitrageDetails['id'], JsonArbitrageDetails> | null;
  error: GenericError | null;
  requestStatus: RequestStatus;
  detailsRequestStatus: RequestStatus;
}
