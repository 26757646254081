import { createAsyncThunk } from '@reduxjs/toolkit';
import { createWsAction } from 'src/store/utils';
import { selectCurrentLoan } from 'src/store/loans/selectors';
import type { RootState } from 'src/store';
import { selectCurrentProject } from 'src/store/retainers/selectors';
import { selectArbitrageInfoStatus } from 'src/store/shared/arbitrage-info/selectors';
import { ArbitrageInfoCommands } from './types';
import type { GetArbitrageDataWsPayload } from './types';

export const getArbitrageDataWs = createWsAction<
  GetArbitrageDataWsPayload,
  GetArbitrageDataWsPayload
>('loans/getArbitrageDataWs', (data) => ({
  command: ArbitrageInfoCommands.GetArbitrage,
  ...data,
}));

export const getArbitrageData = createAsyncThunk('loans/getArbitrageData', (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const projectID = selectCurrentProject(state)?.id;
  const loanID = selectCurrentLoan(state)?.id;
  const isPending = selectArbitrageInfoStatus(state) === 'pending';
  if (!isPending) {
    thunkApi.dispatch(
      getArbitrageDataWs({ ...(projectID && { projectID }), ...(loanID && { loanID }) }),
    );
  }
});
