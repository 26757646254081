import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { listen } from 'src/store/loans/actions';
import { LoanLiquidityCommands } from './types';
import type {
  JsonLoanLiquiditySuccess,
  LoanLiquidityState,
  LoanLiquidityViewFilters,
  LoanLiquidityAggregatedValuesFilter,
} from './types';

const initialState: LoanLiquidityState = {
  requestStatus: null,
  error: null,
  list: null,
  listCache: {},
  viewFilter: 'supportedPairs',
  aggregatedValuesFilter: 'fromBots',
};

export const loanLiquiditySlice = createSlice({
  name: 'liquidity',
  initialState,
  reducers: {
    loadCache: (state, action: PayloadAction<{ loanId: string }>) => {
      state.list = state.listCache[action.payload.loanId] || null;
    },
    setViewFilter: (state, action: PayloadAction<LoanLiquidityViewFilters>) => {
      state.viewFilter = action.payload || 'supportedPairs';
    },
    setAggregatedValuesFilter: (
      state,
      action: PayloadAction<LoanLiquidityAggregatedValuesFilter>,
    ) => {
      state.aggregatedValuesFilter = action.payload || 'fromBots';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'liquidity') {
        state.requestStatus = 'pending';
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const allowedCommands = Object.values(LoanLiquidityCommands);

      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;
      console.debug(`loans/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          default:
        }
      }

      const { id } = json;

      if (command === LoanLiquidityCommands.LoanLiquidity) {
        const { liquidity }: JsonLoanLiquiditySuccess = json;

        state.list = liquidity;
        state.listCache[id] = liquidity;
        state.error = null;
        state.requestStatus = 'success';
      }
    });
  },
});

export const liquidityActions = {
  ...loanLiquiditySlice.actions,
};

export default loanLiquiditySlice.reducer;
