import { createWsAction } from 'src/store/utils';
import { LoanFundsCommands } from './types';

export interface AddCommittedFundsPayload {
  loanID: string;
  amount: number;
  token: string;
}

export const addCommittedFunds = createWsAction<AddCommittedFundsPayload, AddCommittedFundsPayload>(
  'loans/addCommittedFunds',
  (data) => ({
    command: LoanFundsCommands.AddCommittedFunds,
    ...data,
  }),
);
