import { createWsAction } from 'src/store/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectorTradingLastMessageId,
  selectorTradingSessionDetails,
} from 'src/store/shared/trading/selectors';
import type { RootState } from 'src/store';
import { TradingCommands } from './types';
import type {
  CreateSessionPayload,
  JsonWebMessage,
  WebMessagePayload,
  JsonWebMessageRequest,
  TradingMessageSession,
} from './types';

export const createSessionWs = createWsAction<TradingMessageSession, TradingMessageSession>(
  'trading/createSessionWs',
  (data) => ({
    command: TradingCommands.CreateSession,
    ...data,
  }),
);

export const createSession = createAsyncThunk(
  'trading/createSession',
  (data: CreateSessionPayload, thunkApi) => {
    const session = String(Math.floor(Math.random() * (99999999999 - 10000000000) + 10000000000));
    const payload = {
      ID: data.groupId,
      session,
    };
    thunkApi.dispatch(createSessionWs(payload));
    return payload;
  },
);

const sendMessageWs = createWsAction<Omit<JsonWebMessageRequest, 'command'>, JsonWebMessage>(
  'trading/sendMessage',
  (data) => ({
    command: TradingCommands.WebMessage,
    ...data,
  }),
);

export const sendMessage = createAsyncThunk(
  'trading/sendMessage',
  (data: WebMessagePayload, thunkApi) => {
    const sessionDetails = selectorTradingSessionDetails(thunkApi.getState() as RootState);
    if (!sessionDetails) {
      return;
    }
    const { ID, session } = sessionDetails;
    const lastMessageID = selectorTradingLastMessageId(thunkApi.getState() as RootState);
    const messageID = lastMessageID + 1;
    const { message } = data;
    thunkApi.dispatch(sendMessageWs({ session, message, messageID, ID }));
  },
);
