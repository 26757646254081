import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import type { Action, Options } from './types';
import { error } from './actions';
import * as actionTypes from './actionTypes';
import ReduxWebSocket from './ReduxWebSocket';

/**
 * Default middleware creator options.
 * @private
 */
const defaultOptions = {
  dateSerializer: null,
  prefix: actionTypes.DEFAULT_PREFIX,
  reconnectInterval: 2000,
  reconnectOnClose: false,
  reconnectOnError: true,
  serializer: JSON.stringify,
};

/**
 * Create a middleware.
 *
 * @param {Options} rawOptions
 *
 * @returns {Middleware}
 */
export default (rawOptions?: Options): Middleware => {
  const options = { ...defaultOptions, ...rawOptions };
  const { prefix, dateSerializer } = options;
  const actionPrefixExp = RegExp(`^${prefix}::`);

  // Create a new redux websocket instance.
  const reduxWebsocket = new ReduxWebSocket(options);

  // Define the list of handlers, now that we have an instance of ReduxWebSocket.
  const handlers = {
    [actionTypes.WEBSOCKET_CONNECT]: reduxWebsocket.connect,
    [actionTypes.WEBSOCKET_DISCONNECT]: reduxWebsocket.disconnect,
    [actionTypes.WEBSOCKET_SEND]: reduxWebsocket.send,
  };

  // Middleware function.
  return (store: MiddlewareAPI) => (next) => (action) => {
    const typedAction = action as Action;

    const { dispatch } = store;
    const { type: actionType } = typedAction;

    // Check if action type matches prefix
    if (actionType?.match(actionPrefixExp)) {
      const baseActionType = typedAction.type.replace(actionPrefixExp, '') as
        | 'CONNECT'
        | 'DISCONNECT'
        | 'SEND';
      const handler = Reflect.get(handlers, baseActionType);

      if (
        typedAction.meta?.timestamp &&
        typeof typedAction.meta.timestamp !== 'string' &&
        dateSerializer
      ) {
        typedAction.meta.timestamp = String(dateSerializer(typedAction.meta.timestamp));
      }

      if (handler) {
        try {
          handler(store, typedAction);
        } catch (err) {
          dispatch(error(typedAction, err as Error, prefix));
        }
      }
    }

    return next(typedAction);
  };
};
