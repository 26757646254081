import { createAsyncThunk } from '@reduxjs/toolkit';

import omit from 'lodash/omit';

import { wsLoansSend } from 'src/store/ws-loans/actions';
import { createWsAction } from 'src/store/utils';

import { LoanCommands } from './types';

export interface HideLoanPayload {
  loanID?: number;
}

export const hideLoan = createAsyncThunk('loans/hideLoan', (data: HideLoanPayload, thunkApi) => {
  thunkApi.dispatch(
    wsLoansSend({
      command: LoanCommands.HideLoan,
      ...data,
    }),
  );
});

export interface ListenPayload {
  section: string; // either `"overview"` or `exchange.name`
  isInitCall: boolean; // frontend only
  loanID?: number;
  ticker?: string;
}

export const listen = createAsyncThunk('loans/listen', (data: ListenPayload, thunkApi) => {
  thunkApi.dispatch(
    wsLoansSend({
      command: 'Listen',
      ...omit(data, 'isInitCall'),
    }),
  );
});

export interface UpdateCommentPayload {
  comment: string;
}

export const updateComment = createWsAction<UpdateCommentPayload>(
  'loans/updateComment',
  LoanCommands.UpdateComment,
);
