import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import cloneDeep from 'lodash/cloneDeep';
import { LoanExchangesCommands } from './types';
import { listen } from '../actions';

import type { JsonLoanExchangesSuccess, LoanExchangesState } from './types';

const allowedCommands = Object.values(LoanExchangesCommands);

const initialState: LoanExchangesState = {
  requestStatus: null,
  error: null,

  // LoanExchanges, data on Listen
  list: [],
  supportedPairs: [],
  supportedExchanges: [],
  listCache: {},

  hideNotSupportExchange: true,
};

const loanExchangesSlice = createSlice({
  name: 'exchanges', // loans.exchanges
  initialState,
  reducers: {
    resetState: (state) => {
      const clonedInitialState = cloneDeep(initialState);
      state.hideNotSupportExchange = true;
      clonedInitialState.listCache = state.listCache;

      return clonedInitialState;
    },
    toggleEnableFilter: (state) => {
      state.hideNotSupportExchange = !state.hideNotSupportExchange;
    },
    loadCache: (state, action: PayloadAction<{ loanId: string }>) => {
      state.list = state.listCache[action.payload.loanId] || [];
    },
  },
  extraReducers: (builder) => {
    // listen

    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'overview') {
        state.requestStatus = 'pending';
      }
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `loans/exchanges/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      const { id } = json;

      if (command === LoanExchangesCommands.LoanExchanges) {
        const { exchanges, supportedExchanges, pairs }: JsonLoanExchangesSuccess = json;

        state.list = exchanges;
        state.supportedExchanges = supportedExchanges;
        state.supportedPairs = pairs;

        state.listCache[id] = exchanges;

        state.requestStatus = 'success';
        return;
      }

      if (command === 'ok' && okCommand) {
        // state.requestStatus = 'success'
      }
    });
  },
});

export const loanExchangesActions = { ...loanExchangesSlice.actions };
// export const { toggleSidebar } = loanExchangesSlice.actions

export default loanExchangesSlice.reducer;
