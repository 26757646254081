import type { IsoDateString } from 'src/utils/formatting/dates';

import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

export enum KpiCommands {
  AddKPI = 'AddKPI',
  EditKPI = 'EditKPI',
  RemoveKPI = 'RemoveKPI',
}

export interface JsonKpi {
  ID?: number;
  Spread: number;
  Liquidity: number;
  Ticker: string;
  ExchangeID: number;
  Level: number;
}

export interface JsonLoanKpiList {
  Description: string;
  All: JsonKpi[];
}

export const KpiTarget = {
  Spread: 'Spread',
  Depth: 'Depth',
} as const;

export interface FormKpi {
  id?: number;
  target: keyof typeof KpiTarget;
  spread: string;
  liquidity: string;
  ticker: string;
  exchangeID: number;
  level: number;
}

interface PayloadKpiCommon {
  loanID?: number;
  ticker: string;
}

interface PayloadKpiSpread extends PayloadKpiCommon {
  liquidity: string;
  level: number;
  exchangeID: number;
}

interface PayloadKpiDepth extends PayloadKpiCommon {
  spread: string;
}

export type PayloadKpi = PayloadKpiSpread | PayloadKpiDepth;

export type Depth =
  | 0
  | 0.1
  | 0.25
  | 0.5
  | 1
  | 1.5
  | 2
  | 5
  | 10
  | 20
  | -0.1
  | -0.25
  | -0.5
  | -1
  | -1.5
  | -2
  | -5
  | -10
  | -20;

export type LiquidityTuple = [Depth, number];

export type LiquidityMap = Record<IsoDateString, LiquidityTuple[]>;

export interface KpiState {
  error: null | GenericError;
  requestStatus: RequestStatus;
  /*  resNewLoan: RequestStatus;
  resUpdateLoan: RequestStatus; */
  formKpi: FormKpi[] | null;
  kpiList: JsonKpi[] | null;
  selectedEditKpi: JsonKpi | null;
  resAddKpi: RequestStatus;
  resEditKpi: RequestStatus;
  resRemoveKpi: RequestStatus;
}

export interface JsonLoanKpiSuccess {
  command: 'LoanKPI';
  loanID: number;
  kpi: JsonKpi[];
}
