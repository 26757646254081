import * as Sentry from '@sentry/react';
import { message } from 'antd';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import type { WalletData, WalletListData } from 'src/store/cold-wallets/types';
import type { SerializedError } from '@reduxjs/toolkit';

export const parseWallet = (
  resObj?: WalletListData,
  error?: FetchBaseQueryError | SerializedError,
): WalletData[] => {
  if (error) {
    return [];
  }

  try {
    return resObj?.data.list ?? [];
  } catch (error) {
    if (error instanceof Error) {
      message.error(`Error on parse wallet data ${error.message}`);
      Sentry.captureException(new Error('DEX API: wrong wallets list data format'), {
        extra: {
          error: error.message,
          values: resObj,
        },
        level: 'error',
      });
    }
    console.error(error);
  }

  return [];
};
