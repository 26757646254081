import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

export enum LoanTradingViewCommands {
  Chart = 'Chart',
}

export interface JsonChartSuccess {
  command: 'Chart';
  ticker: string;
  chart: JsonChartData[];
}

type JsonChartDataType = 'details' | 'candles';

type DateTimeString = string;

export interface JsonChartCandlesData {
  c: number;
  d: DateTimeString;
  h: number;
  l: number;
  o: number;
  v: string;
}

type JsonChartDetailsData = string;

interface JsonChartData {
  type: JsonChartDataType;
  data: JsonChartDetailsData[] | JsonChartCandlesData[];
}

export interface ChartCandlesData {
  time: DateTimeString;
  open: number;
  high: number;
  low: number;
  close: number;
  value: string;
}

export interface LoanTradingViewState {
  data: ChartCandlesData[];
  status: RequestStatus;
  error: GenericError | null;
}
