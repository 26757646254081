import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';
import type { JsonLinechartPart } from 'src/store/loans/types';

export interface LoansOverviewState {
  error: GenericError | null;
  requestStatus: RequestStatus;
  linechartWeek?: JsonLinechartPart[];
  linechartYear?: JsonLinechartPart[];
  // UI params for linechart
  linechartRange: 'Week' | 'Year';
  linechartStacked: boolean;
}

export enum LoansOverviewCommands {
  History = 'History',
}

export interface JsonHistoryResponse {
  command: LoansOverviewCommands.History;
  loanID: number;
  linechartWeek: JsonLinechartPart[];
  linechartYear: JsonLinechartPart[];
}
