import { TokenMapEndpoints } from './rtkTokenMapApi/types';
import { rtkTokenMapApi } from './rtkTokenMapApi';
import type { BaseTokenMapData, BindNewMap, MapListData } from './type';

export const TokenMapApi = rtkTokenMapApi.injectEndpoints({
  endpoints: (build) => ({
    getTokenMap: build.query<MapListData, void>({
      query: () => ({ url: TokenMapEndpoints.GetTokenMap, method: 'GET' }),
      providesTags: ['TokenMap'],
    }),

    bindTokenMap: build.mutation<BaseTokenMapData, BindNewMap>({
      query: (body) => ({ url: TokenMapEndpoints.BindTokenMap, method: 'POST', body }),
      invalidatesTags: ['TokenMap'],
    }),
    unbindTokenMap: build.mutation<BaseTokenMapData, { symbol: string }>({
      query: (body) => ({ url: TokenMapEndpoints.UnbindTokenMap, method: 'POST', body }),
      invalidatesTags: ['TokenMap'],
    }),
  }),
});

export const { useGetTokenMapQuery, useBindTokenMapMutation, useUnbindTokenMapMutation } =
  TokenMapApi;
