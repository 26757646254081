import { createAsyncThunk } from '@reduxjs/toolkit';

import { wsLoansSend } from 'src/store/ws-loans/actions';
import { ReportCommands } from './types';

export enum ReportType {
  EmailGeneral = 0,
  EmailWeekly = 1,
  EmailExportTrades = 2,
  TelegramGeneral = 3,
  DepthReport = 5,
}

interface LoanId {
  loanId: string;
}
interface ProjectId {
  projectId: string;
}

type RequestReportTypeId = LoanId | ProjectId;

export interface RequestReportPayloadBase {
  accounts: string[];
  startDate: string;
  endDate: string;
  report: ReportType;
}

export type RequestReportPayload = RequestReportPayloadBase & RequestReportTypeId;

interface ConfirmReportPayload {
  id: number;
}

export const requestReport = createAsyncThunk(
  'reports/requestReport',
  (payload: RequestReportPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ReportCommands.GetReport,
        ...payload,
      }),
    ),
);

export const confirmReport = createAsyncThunk(
  'reports/confirmReport',
  (payload: ConfirmReportPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ReportCommands.SendReport,
        ...payload,
      }),
    ),
);

export const cancelReport = createAsyncThunk(
  'reports/cancelReport',
  (payload: ConfirmReportPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ReportCommands.CancelReport,
        ...payload,
      }),
    ),
);
