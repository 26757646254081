import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { rtkQueryErrorActions } from 'src/store/error/slice';

interface RtkQueryAction {
  payload: {
    status: number;
  };
  meta: {
    arg: {
      type: 'query' | 'mutation';
      endpointName: string;
    };
  };
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action: unknown) => {
    const rtkAction = action as RtkQueryAction;
    if (isRejectedWithValue(action) && ['query', 'mutation'].includes(rtkAction.meta.arg.type)) {
      if (
        (rtkAction.payload.status === 404 &&
          rtkAction.meta.arg.endpointName === 'getColdWallets') ||
        (rtkAction.payload.status === 404 &&
          rtkAction.meta.arg.endpointName === 'getWalletProjectSnapshot') ||
        (rtkAction.payload.status === 404 &&
          rtkAction.meta.arg.endpointName === 'getWalletSnapshot')
      ) {
        // Because the lack of wallets comes in the form of an error 404, we don't want to show an error notification
        return next(rtkAction);
      }
      api.dispatch(rtkQueryErrorActions.setError(rtkAction.payload));
      return next(action);
    }

    return next(action);
  };
