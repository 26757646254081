import { createSlice } from '@reduxjs/toolkit';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { listen as listenLoans } from 'src/store/loans/actions';
import { listen as listenRetainers } from 'src/store/retainers/actions';
import type { ArbitrageInfoState, JsonArbitrageInfo } from './types';
import { ArbitrageInfoCommands } from './types';
import { getArbitrageData } from './actions';

const allowedCommands = Object.values(ArbitrageInfoCommands);

const initialState: ArbitrageInfoState = {
  total: null,
  details: [],
  status: null,
  error: null,
};

const arbitrageInfoSlice = createSlice({
  name: 'arbitrageInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listenRetainers.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'arbitrage-info') {
        state.status = 'pending';
      }
    });

    builder.addCase(listenLoans.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'arbitrage-info') {
        state.status = 'pending';
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `shared/arbitrage-info/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case ArbitrageInfoCommands.GetArbitrage:
            state.status = 'error';
            break;

          default:
        }

        return;
      }

      if (command === ArbitrageInfoCommands.ArbitrageInfo) {
        const { details, total } = json as JsonArbitrageInfo;
        state.total = total;
        state.details = details;
        state.status = 'success';
      }
    });
  },
});

export const arbitrageInfoActions = { ...arbitrageInfoSlice.actions, getArbitrageData };

export default arbitrageInfoSlice.reducer;
