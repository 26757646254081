// /import { createSelector } from '@reduxjs/toolkit';
import type { WsLoansState } from 'src/store/ws-loans/slice';

interface Store {
  wsLoans: WsLoansState;
}

export const selectWsLoansStatus = (store: Store) => store.wsLoans.status;
export const selectError = (store: Store) => store.wsLoans.error;
export const selectIsWsReconnecting = (store: Store) => store.wsLoans.isReconnecting;

export default null;
