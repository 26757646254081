import { isAnyOf } from '@reduxjs/toolkit';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { initThresholdSlice } from 'src/store/shared/threshold/factory';
import { registerErrorMessage } from 'src/store/utils/errors';
import type { JsonFailure } from 'src/store/types';
import type { JsonAccountThreshold } from '../../shared/threshold/types';

export enum ProjectAccountThresholdCommands {
  AccountThresholds = 'AccountThresholds',
  UpsertAccountThreshold = 'UpsertAccountThreshold',
  DeleteAccountThreshold = 'DeleteAccountThreshold',
}

registerErrorMessage(ProjectAccountThresholdCommands.DeleteAccountThreshold, {
  title: 'Delete Account Threshold Error',
  getDescription: (json: JsonFailure) => `${json.message}`,
});

registerErrorMessage(ProjectAccountThresholdCommands.UpsertAccountThreshold, {
  title: 'Add Account Threshold Error',
  getDescription: (json: JsonFailure) => `${json.message}`,
});

const projectAccountsThresholdSlice = initThresholdSlice({
  prefix: 'retainers',
  sliceName: 'threshold',
  commands: ProjectAccountThresholdCommands,
});

startListening({
  matcher: isAnyOf(
    projectAccountsThresholdSlice.actions.setAccountThreshold,
    projectAccountsThresholdSlice.actions.deleteAccountThreshold,
  ),
  effect: (action, api) => {
    const { command: _, ...thresholdData } = action.payload as {
      command: string;
    } & JsonAccountThreshold;
    api.dispatch(projectAccountsThresholdSlice.actions.setThresholdUpdateData(thresholdData));
  },
});

export const retainerAccountsThresholdActions = projectAccountsThresholdSlice.actions;
export default projectAccountsThresholdSlice.reducer;
