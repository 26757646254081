import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { parseResponse } from 'src/store/utils';
import type { RequestStatus } from 'src/store/types';
import type { GenericError } from 'src/store/utils/errors';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { JsonBalance } from 'src/store/shared/balances/types';
import type { JsonLinechartOverview } from 'src/store/loans/types';
import { listen } from '../actions';
import type { JsonRetainersExchange, JsonRetainersExchangesSuccess } from '../types';
import { RetainerCommands } from '../types';

interface RetainersOverviewState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  exchanges: JsonRetainersExchange[] | null;
  balances: JsonBalance[] | null;

  linechartWeek: JsonLinechartOverview;
  linechartYear: JsonLinechartOverview;
  linechartRange: 'Week' | 'Year';
  linechartStacked: boolean;
}

const initialState: RetainersOverviewState = {
  requestStatus: null,
  error: null,

  exchanges: null,
  balances: null,

  linechartWeek: [],
  linechartYear: [],
  linechartRange: 'Week',
  linechartStacked: false,
};

const allowedCommands = [RetainerCommands.RetainerOverview];

const retainersOverviewSlice = createSlice({
  name: 'overview', // retainers.overview
  initialState,
  reducers: {
    setLinechartRange(state, action: PayloadAction<{ range: 'Week' | 'Year' }>) {
      state.linechartRange = action.payload.range;
    },
    setLinechartStacked(state, action: PayloadAction<{ stacked: boolean }>) {
      state.linechartStacked = action.payload.stacked;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'overview') {
        state.requestStatus = 'pending';
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      const { command } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === RetainerCommands.RetainerOverview) {
        const { exchanges, balances, linechartWeek, linechartYear }: JsonRetainersExchangesSuccess =
          json;

        state.exchanges = exchanges;
        state.balances = balances;
        state.linechartWeek = linechartWeek;
        state.linechartYear = linechartYear;
        state.requestStatus = 'success';
      }
    });
  },
});

export const retainersOverviewActions = {
  ...retainersOverviewSlice.actions,
};

export default retainersOverviewSlice.reducer;
