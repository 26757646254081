import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { wsLoansSend } from 'src/store/ws-loans/actions';
import type { AddLoanPayload } from 'src/store/loans/terms/types';

export const addLoan = createAsyncThunk('loans/addLoan', (data: AddLoanPayload, thunkApi) => {
  const payload = {
    ...omit(data, ['amounts', 'paybackAmounts']),
    baseAmount: data.amounts.baseAmount,
    quoteAmount: data.amounts.quoteAmount,
    basePaybackAmount: data.paybackAmounts.baseAmount,
    quotePaybackAmount: data.paybackAmounts.quoteAmount,
    residualPaybackAmount: data.paybackAmounts.residualAmount,
  };

  thunkApi.dispatch(
    wsLoansSend({
      command: 'AddLoan',
      ...payload,
      tokens: payload.tokens?.join(','),
    }),
  );

  return data;
});

export type UpdateLoanPayload = Partial<AddLoanPayload> & {
  loanID: number;
  baseAmount?: number;
  quoteAmount?: number;
  basePaybackAmount?: number;
  quotePaybackAmount?: number;
  residualPaybackAmount?: number;
};

export const updateLoan = createAsyncThunk(
  'loans/updateLoan',
  (data: UpdateLoanPayload, thunkApi) => {
    const payload = omit(data, ['amounts', 'paybackAmounts']);

    if (data.amounts) {
      payload.baseAmount = data.amounts.baseAmount;
      payload.quoteAmount = data.amounts.quoteAmount;
    }

    if (data.paybackAmounts) {
      payload.basePaybackAmount = data.paybackAmounts.baseAmount;
      payload.quotePaybackAmount = data.paybackAmounts.quoteAmount;
      payload.residualPaybackAmount = data.paybackAmounts.residualAmount;
    }

    thunkApi.dispatch(
      wsLoansSend({
        command: 'UpdateLoan',
        ...payload,
        tokens: payload.tokens?.join(','),
      }),
    );

    return data;
  },
);
