import type { Dayjs, IsoDateString } from 'src/utils/formatting/dates';
import type { RequestStatus } from 'src/store/types';

import type { GenericError } from 'src/store/utils/errors';

export enum StrikePricesCommands {
  LoanStrikes = 'LoanStrikes',
  AddStrikePrice = 'AddStrikePrice',
  EditStrikePrice = 'EditStrikePrice',
  RemoveStrikePrice = 'RemoveStrikePrice',
}

export interface FormStrikePrice {
  tokensAmount: string;
  strikePrice: number;
  strikeDate: Dayjs;
  endDate: Dayjs;
  strikeType: keyof typeof StrikePriceType;
  note: string;
}

export interface StrikePrice {
  id: number;
  tokensAmount: number;
  strikePrice: number;
  strikeDate: IsoDateString;
  endDate: IsoDateString;
  strikeType?: keyof typeof StrikePriceType;
  note: string;
}

export interface JsonStrikePrice {
  ID: number;
  TokensAmount: number;
  QuoteAmount: number;
  StrikePrice: number;
  StrikeDate: IsoDateString;
  EndDate: IsoDateString;
  StrikeType?: keyof typeof StrikePriceType;
  Note: string;
}

export const StrikePriceType = {
  // -1: 'Invalid',
  1: 'TWAP3',
  10: 'TWAP7',
  20: 'TWAP14',
  30: 'TWAP30',
  40: 'VWAP7',
  50: 'VWAP14',
  100: 'Fixed',
  200: 'Legacy',
} as const;

export interface StrikePricesState {
  error: null | GenericError;
  requestStatus: RequestStatus;
  /*  resNewLoan: RequestStatus;
  resUpdateLoan: RequestStatus; */

  formStrikePrices: Omit<StrikePrice, 'id'>[] | null;
  strikePrices: StrikePrice[] | null;
  selectedStrikePrice: StrikePrice | null;
  resAddStrikePrice: RequestStatus;
  resEditStrikePrice: RequestStatus;
  resRemoveStrikePrice: RequestStatus;
}
export interface JsonLoanStrikesSuccess {
  command: 'LoanStrikes';
  loanID: number;
  strikes: JsonStrikePrice[];
}
