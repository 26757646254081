import { rtkAuthApi } from 'src/store/shared/rtkAuthApi';
import { AuthApiEndpoints } from 'src/store/shared/rtkAuthApi/types';
import map from 'lodash/map';
import replace from 'lodash/replace';
import slice from 'lodash/slice';

export interface SignUpData {
  fullname: string;
  company: string;
  referredBy: string;
  email: string;
  password: string;
}

interface LoginData {
  email: string;
  password: string;
}

interface AccessToken {
  accessToken: string;
}

interface ConfirmEmailData {
  token: string;
}

interface Verify2FAData {
  mfaCode: string;
}

interface Verify2FAResponse {
  success: boolean;
  message: string;
  accessToken: string;
  recoveryCodes: string[];
  codesColumns: string[][];
}

interface Authenticate2FAData {
  mfaCode: string;
}

interface QrCodeData {
  success: boolean;
  data: string;
}

interface ResetPasswordData {
  email: string;
}

interface NewPasswordData {
  password: string;
  token: string;
}

interface RecoveryCode {
  code: string;
}

interface RecoveryData {
  success: boolean;
  message: string;
  accessToken: string;
}

export const signUpApi = rtkAuthApi.injectEndpoints({
  endpoints: (build) => ({
    postSignUpData: build.mutation<AccessToken, SignUpData>({
      query: (signUpData) => ({
        url: AuthApiEndpoints.Register,
        method: 'POST',
        body: signUpData,
      }),
    }),
    postConfirmEmail: build.mutation<void, ConfirmEmailData>({
      query: (confirmEmailData) => ({
        url: AuthApiEndpoints.ConfirmEmail,
        method: 'POST',
        body: confirmEmailData,
      }),
    }),
    postResendConfirmationEmail: build.mutation<void, void>({
      query: () => ({
        url: AuthApiEndpoints.ResendConfirmationLink,
        method: 'POST',
      }),
    }),
    postVerify2FA: build.mutation<Verify2FAResponse, Verify2FAData>({
      query: (code2FA) => ({
        url: AuthApiEndpoints.VerifyMfa,
        method: 'POST',
        body: code2FA,
      }),
      transformResponse: (response: Verify2FAResponse) => {
        const recoveryCodes = map(response.recoveryCodes, (recoveryCode) =>
          replace(recoveryCode, /(.{4})/, '$1–'),
        );
        const codesColumns = Array.from({ length: 8 }, (_, i) => slice(recoveryCodes, i, i + 1));

        return { ...response, codesColumns };
      },
    }),
    postGenerateQr: build.mutation<QrCodeData, void>({
      query: () => ({
        url: AuthApiEndpoints.GenerateMfaQr,
        method: 'POST',
      }),
    }),
    postAuthenticate2FA: build.mutation<AccessToken, Authenticate2FAData>({
      query: (code2FA) => ({
        url: AuthApiEndpoints.AuthenticateMfa,
        method: 'POST',
        body: code2FA,
      }),
    }),
    postRefreshToken: build.mutation<AccessToken, void>({
      query: () => ({
        url: AuthApiEndpoints.RefreshToken,
        method: 'POST',
      }),
    }),
    postLogin: build.mutation<AccessToken, LoginData>({
      query: (loginData) => ({
        url: AuthApiEndpoints.Login,
        method: 'POST',
        body: loginData,
      }),
    }),
    postLogout: build.mutation<void, void>({
      query: () => ({
        url: AuthApiEndpoints.Logout,
        method: 'POST',
      }),
    }),
    postResetPassword: build.mutation<void, ResetPasswordData>({
      query: (resetPasswordData) => ({
        url: AuthApiEndpoints.ResetPassword,
        method: 'POST',
        body: resetPasswordData,
      }),
    }),
    postReset2FA: build.mutation<AccessToken, void>({
      query: (ress2FAData) => ({
        url: AuthApiEndpoints.ResetMfa,
        method: 'POST',
        body: ress2FAData,
      }),
    }),
    putResetPassword: build.mutation<void, NewPasswordData>({
      query: (newPasswordData) => ({
        url: AuthApiEndpoints.ResetPassword,
        method: 'PUT',
        body: newPasswordData,
      }),
    }),
    postRecover: build.mutation<RecoveryData, RecoveryCode>({
      query: (recoveryCode) => ({
        url: AuthApiEndpoints.Recover2FA,
        method: 'POST',
        body: recoveryCode,
      }),
    }),
  }),
});

export const {
  usePostSignUpDataMutation,
  usePostConfirmEmailMutation,
  usePostResendConfirmationEmailMutation,
  usePostVerify2FAMutation,
  usePostGenerateQrMutation,
  usePostAuthenticate2FAMutation,
  usePostRefreshTokenMutation,
  usePostLoginMutation,
  usePostLogoutMutation,
  usePostResetPasswordMutation,
  usePostReset2FAMutation,
  usePutResetPasswordMutation,
  usePostRecoverMutation,
} = signUpApi;
