import type { JsonFailure, JsonListenResponse, JsonSuccess } from 'src/store/types';
import { createAction } from '@reduxjs/toolkit';
import { WsChannel } from 'src/store/middleware/handleWsRequest';
import isFunction from 'lodash/isFunction';
import type {
  AccountLiquidityTree,
  JsonLoanLiquidityExtendedTree,
} from 'src/store/loans/liquidity/types';
import has from 'lodash/has';
import type {
  JsonLoanExchangeExtended,
  MarketVolumesItem,
  TradingVolumeItem,
} from 'src/store/loans/exchanges/types';
import type { GenericError } from 'src/store/utils/errors';
import { onError, onParseError } from 'src/store/utils/errors';
import type { LiquidityPropItem, LiquidityValue } from 'src/store/loans/types';

export const parseResponse = <T extends object | undefined | null = undefined | null>(
  payload: { message: string },
  allowedCommands: string[] = [],
) => {
  const result: {
    json: T;
    error?: GenericError | null;
    errorCommand?: string;
    okCommand?: string;
    skipProcessing: boolean;
  } = {
    json: null as T,
    // error: null,
    // errorCommand: null,
    // okCommand: null,
    skipProcessing: false,
  };

  let json;

  try {
    json = JSON.parse(payload.message) as JsonListenResponse<(typeof allowedCommands)[number], T>;
    const { command } = json;

    if (![...allowedCommands, 'ok', 'error'].includes(command)) {
      result.json = json;
      result.skipProcessing = true;
      return result;
    }

    if (command === 'error') {
      const { errorCommand } = json as JsonFailure;
      if (errorCommand && !allowedCommands.includes(errorCommand)) {
        result.json = json;
        result.skipProcessing = true;
        return result;
      }

      result.json = json;
      result.error = onError(json as JsonFailure);
      result.errorCommand = errorCommand;
      result.skipProcessing = false;
      return result;
    }

    if (command === 'ok') {
      const { okCommand } = json as JsonSuccess;
      if (okCommand && !allowedCommands.includes(okCommand)) {
        result.json = json;
        result.skipProcessing = true;
        return result;
      }

      result.json = json;
      result.okCommand = okCommand;
      result.skipProcessing = false;
      return result;
    }

    result.json = json;
    result.skipProcessing = false;
    return result;
  } catch (err: unknown) {
    result.json = undefined as T;
    result.error = onParseError(err as typeof Error);
    result.skipProcessing = false;
    return result;
  }
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export function createWsAction(
  type: string,
  callback: () => { command: string },
): ReturnType<
  typeof createAction<() => { payload: ReturnType<typeof callback>; meta: any }, string> // eslint-disable-line @typescript-eslint/no-explicit-any
>;

export function createWsAction<T, P>(
  type: string,
  callback: (data: T) => { command: string } & P,
): ReturnType<
  typeof createAction<(data: T) => { payload: ReturnType<typeof callback>; meta: any }, string> // eslint-disable-line @typescript-eslint/no-explicit-any
>;

export function createWsAction<T>(
  type: string,
  command: string,
): ReturnType<
  typeof createAction<(data: T) => { payload: T & { command: string }; meta: any }, string> // eslint-disable-line @typescript-eslint/no-explicit-any
>;

export function createWsAction<T = undefined, P = undefined>(
  type: string,
  callback: string | (() => { command: string }) | ((data?: T) => { command: string } & P),
) {
  return createAction(type, (data?: T) => ({
    payload: isFunction(callback)
      ? callback(data)
      : {
          command: callback,
          ...(data && { ...data }),
        },
    meta: {
      ws: WsChannel.Loans,
    },
  }));
}

export const isJsonLoanExchangeExtended = (
  rec: JsonLoanExchangeExtended | AccountLiquidityTree,
): rec is JsonLoanExchangeExtended => has(rec, 'children');

export const isJsonLoanExchangeExtendedTree = (
  rec: JsonLoanLiquidityExtendedTree | AccountLiquidityTree,
): rec is JsonLoanLiquidityExtendedTree => has(rec, 'children');

export const isMarketVolumesItem = (
  volumesArray: TradingVolumeItem | MarketVolumesItem,
): volumesArray is MarketVolumesItem => !has(volumesArray, 'Ticker');

export const isLiquidityPropItem = (
  value: LiquidityValue | LiquidityPropItem | undefined,
): value is LiquidityPropItem => {
  if (!value) {
    return false;
  }

  return 'avgLiquidityByDate' in value;
};

interface SortableItem {
  name: string;
  id: number;
}

const SORT_HANDLERS = {
  name_asc: (a: SortableItem, b: SortableItem) => a.name.localeCompare(b.name),
  name_desc: (a: SortableItem, b: SortableItem) => b.name.localeCompare(a.name),
  created_asc: (a: SortableItem, b: SortableItem) => a.id - b.id,
  created_desc: (a: SortableItem, b: SortableItem) => b.id - a.id,
} as const;

export const filterAndSort = <T extends SortableItem>(
  items: T[] | null,
  sortKey: string,
  searchValue = '',
) => {
  if (!items) return [];

  let filteredData = items;

  if (searchValue.length >= 2) {
    const parts = searchValue.toLowerCase().split(' ');
    filteredData = items.filter((item) => {
      const itemString = item.name.toLowerCase();
      return parts.every((token) => itemString.includes(token));
    });
  }

  const sortFn = SORT_HANDLERS[sortKey as keyof typeof SORT_HANDLERS] ?? (() => 0);
  return filteredData.slice().sort(sortFn);
};
