import { createSlice } from '@reduxjs/toolkit';
import type { MenuTheme } from 'antd';

export interface ThemeState {
  name: 'default' | MenuTheme;
}

const initialState: ThemeState = {
  name: 'dark',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    useDarkTheme: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.name = 'dark';
    },
    useLightTheme: (state) => {
      state.name = 'default';
    },
    switchTheme: (state) => {
      state.name = state.name === 'default' ? 'dark' : 'default';
    },
  },
});

// Action creators are generated for each case reducer function
export const { useDarkTheme, useLightTheme, switchTheme } = themeSlice.actions;

export default themeSlice.reducer;
