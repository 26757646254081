export enum ColdWalletsEndpoints {
  LinkColdWallet = '/cold-wallets/link',
  ColdWalletAddress = '/format/:address',
  UnlinkColdWallet = '/cold-wallets/unlink',
  GetColdWallets = '/cold-wallets/list/:projectId',
  GetWalletSnapshot = '/cold-wallets/snapshots/:walletId',
  GetAllWalletsSnapshot = '/cold-wallets/snapshots/project/:projectId',
  AddWalletSnapshot = '/cold-wallets/snapshots/add',
  UpdateColdWallet = '/cold-wallets/update/:walletId',
}
