import { createSlice } from '@reduxjs/toolkit';
import type { RequestStatus } from 'src/store/types';

import map from 'lodash/map';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import type {
  JsonLoanStrikesSuccess,
  JsonStrikePrice,
  StrikePrice,
  StrikePricesState,
} from 'src/store/loans/strike-prices/types';
import { StrikePricesCommands } from 'src/store/loans/strike-prices/types';

import type { SetFormStrikePricesPayload } from 'src/store/loans/strike-prices/actions';
import { addStrikePrice, editStrikePrice, mapFormStrikePrice, removeStrikePrice } from './actions';

const allowedCommands = Object.values(StrikePricesCommands);

const initialState: StrikePricesState = {
  error: null,
  requestStatus: null,
  /*   resNewLoan: null, // to handle new loan creation status
  resUpdateLoan: null, // to handle loan terms updating status */

  strikePrices: null,
  selectedStrikePrice: null,
  formStrikePrices: null,
  resAddStrikePrice: null,
  resEditStrikePrice: null,
  resRemoveStrikePrice: null,
};

const loansStrikePricesSlice = createSlice({
  name: 'StrikePrices',
  initialState,
  reducers: {
    setFormStrikePrices(state, action: { payload: SetFormStrikePricesPayload }) {
      state.formStrikePrices = action.payload.map(mapFormStrikePrice);
    },

    setSelectedStrikePrice(state, action: { payload: StrikePrice | null }) {
      state.selectedStrikePrice = action.payload;
    },

    setStrikePricesRequestStatus(state, action: { payload: RequestStatus }) {
      state.requestStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addStrikePrice, (state) => {
      state.resAddStrikePrice = 'pending';
    });

    builder.addCase(editStrikePrice, (state) => {
      state.resEditStrikePrice = 'pending';
    });

    builder.addCase(removeStrikePrice, (state) => {
      state.resRemoveStrikePrice = 'pending';
    });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(`loans/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (command === 'ok' && StrikePricesCommands.AddStrikePrice === okCommand) {
        state.formStrikePrices = null;
        /*    if (state.resNewLoan === 'pending') {
          state.resNewLoan = 'success';
        }
        if (state.resUpdateLoan === 'pending') {
          state.resUpdateLoan = 'success';
        } */
        state.resAddStrikePrice = 'success';
        return;
      }

      if (command === 'ok' && okCommand === StrikePricesCommands.EditStrikePrice) {
        state.selectedStrikePrice = null;
        state.resEditStrikePrice = 'success';
        return;
      }
      if (command === 'ok' && okCommand === StrikePricesCommands.RemoveStrikePrice) {
        state.resRemoveStrikePrice = 'success';
        return;
      }

      if (command === StrikePricesCommands.LoanStrikes) {
        const { strikes } = json as JsonLoanStrikesSuccess;
        state.strikePrices = map(
          strikes,
          ({
            TokensAmount,
            StrikePrice,
            StrikeDate,
            EndDate,
            StrikeType,
            ID,
            Note,
          }: JsonStrikePrice) => ({
            id: ID,
            tokensAmount: TokensAmount,
            strikePrice: StrikePrice,
            strikeDate: StrikeDate,
            endDate: EndDate,
            strikeType: StrikeType,
            note: Note,
          }),
        );
      }
    });
  },
});

export const strikePricesActions = {
  ...loansStrikePricesSlice.actions,
  addStrikePrice,

  removeStrikePrice,
  editStrikePrice,
};

export default loansStrikePricesSlice.reducer;
