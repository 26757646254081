import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';
import type {
  JsonBalanceHistoryAsset,
  JsonBalanceHistoryExtended,
} from 'src/store/shared/balances/types';

export enum BalanceHistoryCommands {
  BalanceHistory = 'BalanceHistory',
  GetBalanceHistory = 'GetBalanceHistory',
  SetInitialBalance = 'SetInitialBalance',
  PriceHistory = 'PricesHistory',
}

export interface PayloadGetBalanceHistory {
  projectID?: number;
  loanID?: number;
  startDate: string;
  endDate: string;
}

export interface PriceHistoryItemType {
  date: string;
  price: number;
}

export interface PriceHistoryItem {
  price: number;
  token: string;
}

export interface SetInitialBalancePayload {
  date?: string; // YYYY-MM-DD
  accountID: number | string;
  token: string;
  amount: number; // send Number.MIN_SAFE_INTEGER (-9007199254740991) to reset initial balance to undefined
}

export type SetInitialBalanceFormValues = SetInitialBalancePayload & {
  type: 'base' | 'quote' | 'other';
};

export interface SetInitialBalanceModal {
  balance: JsonBalanceHistoryExtended;
  foundAsset: JsonBalanceHistoryAsset[];
  isStart: boolean;
}

export type SetObjInitialBalanceFormValues = Record<string, SetInitialBalanceFormValues>;

export interface JsonBalanceHistoryItem {
  accountID: string;
  date: string;
  historyAmount: number;
  initialAmount: number; // -9007199254740991 if not set
  price: number;
  quotePrice: number;
  token: string;
  type: number; // 0 - quote, 1 - base, 2 - other
}

export interface JsonBalanceHistorySuccess {
  command: BalanceHistoryCommands.BalanceHistory;
  id: number;
  list: JsonBalanceHistoryItem[];
}

export interface JsonPriceHistorySuccess {
  command: BalanceHistoryCommands.PriceHistory;
  id: number;
  list: Record<string, PriceHistoryItem[]>;
}

export interface BalanceHistoryState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  list: JsonBalanceHistoryItem[];

  startDate?: string; // YYYY-MM-DD
  startTime?: string; // HH:mm
  endDate?: string; // YYYY-MM-DD
  endTime?: string; // HH:mm | 'current'

  // set initial balance
  isInitialBalanceModalOpen: boolean;
  formSetInitialBalance?: SetObjInitialBalanceFormValues;
  resSetInitialBalance: RequestStatus;
  priceHistory: Record<string, PriceHistoryItem[]>;
}

export interface HistoryDataItem {
  price: number;
  volume: number;
  volumeUSD: number;
  volumeHint?: string;
  volumeUSDHint?: string;
  isSmallBalance: boolean;
}

export interface UniqueTokensList {
  token: string;
  type: number;
  isSmallBalance?: boolean;
}
