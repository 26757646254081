import { createWsAction } from 'src/store/utils';

import { BalanceHistoryCommands } from './types';
import type { SetInitialBalancePayload, PayloadGetBalanceHistory } from './types';

export const getBalanceHistoryWs = createWsAction<PayloadGetBalanceHistory>(
  'retainers/GetBalanceHistoryWs',
  BalanceHistoryCommands.GetBalanceHistory,
);

export const setInitialBalance = createWsAction<SetInitialBalancePayload, SetInitialBalancePayload>(
  'retainers/SetInitialBalance',
  (
    data = {
      accountID: 0,
      token: '',
      amount: 0,
    },
  ) => ({
    command: BalanceHistoryCommands.SetInitialBalance,
    ...data,
  }),
);
