import { createWsAction } from 'src/store/utils';
import { AdminPanelCommands } from './types';
import type { BindUnbindAccountResult } from './types';

export const bindAccount = createWsAction<BindUnbindAccountResult, BindUnbindAccountResult>(
  'adminPanel/bindAccount',
  (data: BindUnbindAccountResult) => ({
    command: AdminPanelCommands.BindAccount,
    ...data,
  }),
);

export const unbindAccount = createWsAction<BindUnbindAccountResult, BindUnbindAccountResult>(
  'adminPanel/unbindAccount',
  (data: BindUnbindAccountResult) => ({
    command: AdminPanelCommands.BindAccount,
    ...data,
  }),
);
