import { initKpiSlice } from 'src/store/shared/kpi/factory';
import { KpiCommands } from 'src/store/shared/kpi/types';

export enum RetainerKpiCommands {
  KPI = 'RetainerKPI',
}

const { reducer, actions } = initKpiSlice({
  prefix: 'retainers',
  sliceName: 'retainersKpi',
  commands: { ...KpiCommands, ...RetainerKpiCommands },
});

export const retainersKpiActions = actions;
export default reducer;
