import { createSlice } from '@reduxjs/toolkit';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { LoanTradingViewCommands } from './types';
import type { JsonChartSuccess, LoanTradingViewState, JsonChartCandlesData } from './types';

const allowedCommands = Object.values(LoanTradingViewCommands);

const initialState: LoanTradingViewState = {
  data: [],
  status: null,
  error: null,
};

const loanTradingViewSlice = createSlice({
  name: 'loanTradingView',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(
        `loans/funds/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command } = json;

      if (error) {
        state.error = error;

        switch (errorCommand) {
          default:
        }

        return;
      }

      if (command === LoanTradingViewCommands.Chart) {
        const { chart } = json as JsonChartSuccess;
        const jsonCandlesData =
          (chart?.find((entry) => entry.type === 'candles')?.data as JsonChartCandlesData[]) ?? [];
        state.data = jsonCandlesData.map((candle) => ({
          time: candle.d.split(' ')[0],
          open: candle.o,
          high: candle.h,
          low: candle.l,
          close: candle.c,
          value: candle.v,
        }));
      }
    });
  },
});

export const loanTradingViewActions = { ...loanTradingViewSlice.actions };

export default loanTradingViewSlice.reducer;
