import { createSlice } from '@reduxjs/toolkit';

import { parseResponse } from 'src/store/utils';
import { actionOnMessage } from 'src/store/ws-loans/actions';

import { listen } from '../actions';

import type { JsonLoanStatsSuccess, LoanStatsState } from './types';
import { LoanStatsCommands } from './types';

const allowedCommands = Object.values(LoanStatsCommands);

const initialState: LoanStatsState = {
  requestStatus: null,
  error: null,
  otherTokens: '',
  // LoanStats, data on Listen
  pair: '',
  token: '',
  currentPrice: 0,
  maxProfit: 0, // including funds
  description: '', // hint for maxProfit
  baseCurrentVolume: 0, // in token
  baseCurrentVolumeUSD: 0, // in usd
  paybackAmountBase: 0, // in token
  quoteCurrentVolume: 0, // in usd
  paybackAmountQuote: 0, // in usd
  company: '',
  age: '',
  started: '',
  ended: '',
};

const loanStatsSlice = createSlice({
  name: 'stats', // loans.stats
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // listen

    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'overview') {
        state.requestStatus = 'pending';
      }
    });

    // builder.addCase(listen.fulfilled, (state, action) => {
    //   const { isInitCall, section } = action.meta.arg;
    //   if (isInitCall && section === 'overview') {
    //     state.requestStatus = 'success';
    //   }
    // });

    // on WS message

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(
        `loans/stats/processing ${actionOnMessage.toString()}`,
        action,
        allowedCommands,
      );

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === LoanStatsCommands.LoanStats) {
        const {
          pair,
          token,
          currentPrice,
          maxProfit,
          description,
          baseCurrentVolume,
          baseCurrentVolumeUSD,
          paybackAmountBase,
          quoteCurrentVolume,
          paybackAmountQuote,
          company,
          age, // depreacted?
          started,
          ended,
        }: JsonLoanStatsSuccess = json;

        state.pair = pair;
        state.token = token;
        state.currentPrice = currentPrice;
        state.maxProfit = maxProfit;
        state.description = description;
        state.baseCurrentVolume = baseCurrentVolume;
        state.baseCurrentVolumeUSD = baseCurrentVolumeUSD;
        state.paybackAmountBase = paybackAmountBase;
        state.quoteCurrentVolume = quoteCurrentVolume;
        state.paybackAmountQuote = paybackAmountQuote;
        state.company = company;
        state.age = age;
        state.started = started;
        state.ended = ended;

        state.requestStatus = 'success';
        return;
      }

      if (command === 'ok') {
        switch (okCommand) {
          // case 'Projects':
          //   state.list = json.list;
          //   break;

          default:
        }
      }
    });
  },
});

export const loanStatsActions = { ...loanStatsSlice.actions };
// export const { toggleSidebar } = loanStatsSlice.actions;

export default loanStatsSlice.reducer;
