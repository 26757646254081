export enum AuthApiEndpoints {
  Register = '/auth/register',
  ConfirmEmail = '/email/confirm-email',
  ResendConfirmationLink = '/email/resend-confirmation-link',
  VerifyMfa = '/mfa/verify',
  GenerateMfaQr = '/mfa/generate',
  AuthenticateMfa = '/mfa/authenticate',
  ResetMfa = '/mfa/reset',
  RefreshToken = '/auth/refresh-token',
  Login = '/auth/login',
  Logout = '/auth/logout',
  ResetPassword = '/password',
  Recover2FA = '/mfa/recover',
}

export enum AuthApiActionTypes {
  SetAccessToken = 'signup/setAccessToken',
  Signout = 'signup/setSignout',
}

export interface JwtPayload {
  sub: string;
  iss: string;
  isMfaEnabled: boolean;
  isMfaAuthenticated: boolean;
  isEmailConfirmed: boolean;
  iat: string;
  exp: string;
}

export enum ProfileApiEndpoints {
  Profile = '/profile',
}

export enum UsersApiEndpoints {
  Users = '/users',
  User = '/users/:id',
  UserBlock = '/users/:id/block',
  UserUnblock = '/users/:id/unblock',
  UserRole = '/users/:id/roles',
  UserStatus = '/users/status-list',
}

export enum RolesApiEndpoints {
  Roles = '/roles',
  Role = '/roles/:id',
}
