import type {
  AdditionalRetainerProjectPayload,
  AddRetainerProjectPayload,
  JsonAddRetainerProjectPayload,
  JsonUpdateRetainerProjectPayload,
} from 'src/store/retainers/terms/types';
import { RetainerTermsCommands } from 'src/store/retainers/terms/types';
import { createWsAction } from 'src/store/utils';

export const addRetainerProject = createWsAction<
  AddRetainerProjectPayload,
  JsonAddRetainerProjectPayload
>('retainers/addProject', (data: AddRetainerProjectPayload) => ({
  command: RetainerTermsCommands.AddProject,
  ...data,
  tokens: data.tokens.join(','),
}));

export type UpdateRetainerProjectPayload = Partial<AddRetainerProjectPayload> &
  AdditionalRetainerProjectPayload;

export const updateRetainerProject = createWsAction<
  UpdateRetainerProjectPayload,
  JsonUpdateRetainerProjectPayload
>('retainers/updateRetainer', (data: UpdateRetainerProjectPayload) => ({
  command: RetainerTermsCommands.UpdateRetainer,
  ...data,
  tokens: data.tokens?.join(','),
}));
