import dayjs from 'src/utils/formatting/dates';
import type { Dayjs } from 'src/utils/formatting/dates';

export const isComparisonDateNow = (date: Dayjs | string) =>
  dayjs.utc(date).isSame(dayjs.utc(), 'date');

export const getComparisonTimeOptionValue = (comparisonDate: Dayjs | string) => {
  const historyItemDateDayjs = dayjs(comparisonDate);
  const tomorrow = dayjs().add(1, 'day').startOf('day');

  const correctedItemDate =
    historyItemDateDayjs.format('HH:mm') === '23:59'
      ? historyItemDateDayjs.add(1, 'minute')
      : historyItemDateDayjs;

  if (correctedItemDate.isSame(tomorrow, 'date')) {
    return 'current';
  }

  return correctedItemDate.format('HH:mm');
};
