import type { RootState } from 'src/store';
import { createSelector } from '@reduxjs/toolkit';
import { selectExchanges } from 'src/store/shared/exchanges/selectors';
import map from 'lodash/map';
import find from 'lodash/find';

export const selectArbitrageInfoTotal = (state: RootState) => state.arbitrageInfo.total;
export const selectArbitrageInfoDetails = (state: RootState) => state.arbitrageInfo.details;
export const selectArbitrageInfoStatus = (state: RootState) => state.arbitrageInfo.status;

export const selectArbitrageInfoData = createSelector(
  selectArbitrageInfoDetails,
  selectExchanges,
  (details, exchanges) =>
    map(details, (detail) => {
      const exchange = find(exchanges, (entry) => entry.id === detail.exchangeID);
      return { ...detail, exchange };
    }),
);
