import { createSelector } from '@reduxjs/toolkit';

import { selectExchanges } from 'src/store/shared/exchanges/selectors';
import type { JsonExchange } from 'src/store/shared/exchanges/types';

import type { RootState } from 'src/store';
import type { AutoCompleteItem } from 'src/store/types';
import type { JsonProjectAdmin } from './admin-panel/slice';

import { filterAndSort } from 'src/store/utils';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';

export const selectError = (store: RootState) =>
  store?.retainers?.projects?.error ?? store?.retainers?.adminPanel?.error;

export const selectIsListenSent = (store: RootState) =>
  store.retainers.index.listenStatus === 'success';

export const selectProjects = (store: RootState) => store.retainers.projects.list;
export const selectAccountsAdmin = (store: RootState) => store.retainers.adminPanel.userAccounts;
export const selectProjectsAdmin = (store: RootState) => store.retainers.adminPanel.projects;

export const selectProjectsAdminLoading = (store: RootState) =>
  store.retainers.adminPanel.requestStatus === 'pending';
export const selectIsSidebarOpen = (store: RootState) => store.retainers.index.isSidebarOpen;
export const selectTargetProject = (store: RootState) => store.retainers.index.targetProject;
export const selectTargetExchange = (store: RootState) => store.retainers.index.targetExchange;
export const selectIsRetainersExchangeLoading = (store: RootState) =>
  store.retainers.index.listenStatus === 'pending';
export const selectOverviewActiveTab = (store: RootState) =>
  store.retainers.index.overviewActiveTab;

export const selectSortKey = (state: RootState) => state.retainers.projects.sortKey;
export const selectSearchInput = (state: RootState) => state.retainers.projects.searchInput;

export const selectCurrentProject = createSelector(
  selectTargetProject,
  selectProjectsAdmin,
  (targetProject: string, items: JsonProjectAdmin[] | null) => {
    if (items === null || !targetProject) {
      return undefined;
    }

    return find(items, { id: +targetProject }) ?? null;
  },
);

export const selectDefaultProject = createSelector(
  selectProjectsAdmin,
  (items: JsonProjectAdmin[] | null) => first(sortBy(items, 'id')) ?? null,
);

export const selectCurrentExchange = createSelector(
  selectTargetExchange,
  selectExchanges,
  (targetExchange: string, items: JsonExchange[]) => {
    const currentExchange: JsonExchange | undefined = targetExchange
      ? (find(items, { id: +targetExchange }) ?? undefined)
      : undefined;
    return currentExchange;
  },
);

export const selectNextProject = createSelector(
  selectTargetProject,
  selectProjectsAdmin,
  selectSortKey,
  (targetProject: string, items: JsonProjectAdmin[] | null, sortKey: string) => {
    if (!items || !targetProject) return undefined;

    const sortedItems = filterAndSort(items, sortKey);
    const currentIndex = findIndex(sortedItems, { id: +targetProject });
    const nextIdx = currentIndex + 1;
    return nextIdx < sortedItems.length ? sortedItems[nextIdx] : undefined;
  },
);

export const selectPrevProject = createSelector(
  selectTargetProject,
  selectProjectsAdmin,
  selectSortKey,
  (targetProject: string, items: JsonProjectAdmin[] | null, sortKey: string) => {
    if (!items || !targetProject) return undefined;

    const sortedItems = filterAndSort(items, sortKey);
    const currentIndex = findIndex(sortedItems, { id: +targetProject });
    const prevIdx = currentIndex - 1;
    return prevIdx >= 0 ? sortedItems[prevIdx] : undefined;
  },
);

export const selectSortedProjects = createSelector(
  [selectProjectsAdmin, selectSortKey, selectSearchInput],
  filterAndSort,
);

export const selectIsVisibleSideBar = createSelector(
  selectProjectsAdmin,
  (items: JsonProjectAdmin[] | null) => (items ?? [])?.length > 1,
);

export const selectRetainerCompanies = (state: RootState) => state.retainers.projects.companies;

export const selectRetainerCompanyOptions = createSelector(
  selectRetainerCompanies,
  (items: string[]) => {
    const companyOptions: AutoCompleteItem[] = items.map((company: string) => ({
      key: company,
      value: company,
      label: company,
    }));

    return companyOptions;
  },
);

export const selectIsNewProjectModalOpen = (store: RootState) =>
  store.retainers.index.isNewProjectModalOpen;

const parseAccountName = (name: string) => {
  const cleanedName = name.replace('➕ ', '');
  const [email, company] = cleanedName.split('<br/><b>');
  return {
    email,
    company: company?.replace('</b>', ''),
  };
};

export const selectAccountDetails = createSelector(
  [selectAccountsAdmin, selectCurrentProject],
  (accounts, currentProject) => {
    if (!currentProject) return { linkedAccountDetails: [], notLinkedAccountDetails: [] };

    const linkedAccounts = currentProject.accounts ?? [];
    const notLinkedAccounts = accounts.filter((account) => !linkedAccounts.includes(account.id));

    const linkedAccountDetails = linkedAccounts
      .map((accountId) => {
        const account = accounts.find((acc) => acc.id === accountId);
        if (!account) return null;
        return {
          id: accountId,
          details: parseAccountName(account.name),
        };
      })
      .filter((account) => account !== null);

    const notLinkedAccountDetails = notLinkedAccounts.map((account) => ({
      id: account.id,
      details: parseAccountName(account.name),
    }));

    return { linkedAccountDetails, notLinkedAccountDetails, currentProject };
  },
);
