import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

export enum ExchangeCommands {
  Exchanges = 'Exchanges',
}

export interface JsonExchange {
  id: number;
  name: string;
  ticker: string;
}

export interface JsonExchangesListSuccess {
  command: ExchangeCommands.Exchanges;

  list: [
    {
      ID: number;
      Name: string;
      Ticker: string;
      BotName: string;
      PublicLength: number;
      /* 
    // The following fields exist but we don't rely on them
    SecretLength: number;
    PublicMask: string;
    SecretMask: string;
    IPs: string;
    VideoURL: string;
    LogoURL: string;
    Type: number;
    Status: number;
    Crypted: boolean;
    Passphrase: boolean;
    PassphraseNumbersOnly: boolean;
    PassphraseLabel: string; */
    },
  ];
}

export interface ExchangesState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  list: JsonExchange[];
}
