import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { CalculatedThresholdsResult } from 'src/store/shared/threshold/types'; // import { PURGE } from "redux-persist";

export interface CalculatedThresholdsState {
  values: Record<string, CalculatedThresholdsResult>;
}

const initialState: CalculatedThresholdsState = {
  values: {},
};

const calculatedThresholdsSlice = createSlice({
  name: 'calculatedThresholds',
  initialState,
  reducers: {
    save(state, action: PayloadAction<Record<string, CalculatedThresholdsResult>>) {
      state.values = action.payload;
    },
  },
});

export const calculatedThresholdsActions = { ...calculatedThresholdsSlice.actions };

export default calculatedThresholdsSlice.reducer;
