import { createAsyncThunk } from '@reduxjs/toolkit';

import omit from 'lodash/omit';

import { wsLoansSend } from 'src/store/ws-loans/actions';

export interface ListenPayload {
  section: 'servers' | 'log' | 'admin';
  isInitCall: boolean;
  accountID?: string;
}

export const listen = createAsyncThunk('admin/listen', (data: ListenPayload, thunkApi) =>
  thunkApi.dispatch(
    wsLoansSend({
      command: 'Listen',
      ...omit(data, 'isInitCall'),
    }),
  ),
);
