import type { RootState } from 'src/store';

export function customHeaders(originalHeaders: Headers, getState: () => RootState): Headers {
  const { accessToken } = getState().signup;
  const headers = new Headers(originalHeaders);

  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', '*/*');

  return headers;
}
