import type { JsonProject } from './slice';

export enum ProjectCommands {
  Projects = 'Projects',
}

export interface JsonProjectResponse {
  command: ProjectCommands.Projects;
  list: JsonProject[];
  companies: string[];
}
