import { createSlice } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import isNil from 'lodash/isNil';

import type { JsonLogSuccess } from 'src/store/shared/types';
import type {
  JsonAddRetainerProjectSuccess,
  JsonRetainerProjectTermsSuccess,
  RetainerTermsState,
} from './types';
import { RetainerTermsCommands } from './types';
import { updateRetainerProject, addRetainerProject } from './actions';
import { listen } from '../actions';

const initialState: RetainerTermsState = {
  id: undefined,
  details: undefined,
  requestStatus: null,
  requestStatusUpdate: null,
  error: null,
  isRetainerEditing: false, // to handle loan editing mode
  termsEditState: {
    company: false,
    name: false,
    started: false,
    ended: false,
    exchanges: false,
    pairs: false,
    tokens: false,
  },
  log: [],
  logRequestStatus: null,
};

const allowedCommands = Object.values(RetainerTermsCommands);

const projectTermsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setFieldEditState(state, action: { payload: { field: string; value: boolean } }) {
      state.termsEditState = {
        ...state.termsEditState,
        [action.payload.field]: action.payload.value,
      };
    },
    toggleEditing(state, action: { payload: { value?: boolean } }) {
      console.debug('toggleRetainerEdit action:', action);
      state.isRetainerEditing = !isNil(action.payload.value)
        ? action.payload.value
        : !state.isRetainerEditing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateRetainerProject, (state, action) => {
      console.log('retainers/updateRetainerProject:', action);
      state.requestStatusUpdate = 'pending';
    });

    builder.addCase(addRetainerProject, (state, action) => {
      console.log('retainers/updateRetainerProject:', action);
      state.requestStatusUpdate = 'pending';
    });

    builder.addCase(listen.pending, (state, action) => {
      if (action.meta.arg.isInitCall) {
        state.requestStatus = 'pending';
        state.logRequestStatus = 'pending';
      }
      if (!action.meta.arg.projectID) {
        state.details = undefined;
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(`retainers/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;
      if (error) {
        state.error = error;

        switch (errorCommand) {
          case RetainerTermsCommands.AddProject:
          case RetainerTermsCommands.UpdateRetainer:
            state.requestStatusUpdate = 'error';
            break;
          default:
        }
      }

      if (command === RetainerTermsCommands.Terms) {
        const { details, id }: JsonRetainerProjectTermsSuccess = json;
        state.id = id;
        state.details = {
          ...details,
          tokens: details.tokens?.split(',') || [],
        };
        state.requestStatus = 'success';
      }

      if (command === RetainerTermsCommands.Log) {
        const { list }: JsonLogSuccess = json;
        state.log = list;
        state.logRequestStatus = 'success';
      }

      if (command === 'ok' && okCommand === RetainerTermsCommands.UpdateRetainer) {
        const { result: projectID }: JsonAddRetainerProjectSuccess = json;
        state.requestStatusUpdate = 'success';
        console.debug('UpdateRetainer loanID:', projectID, json);
        // const updatedData: UpdateRetainerProjectPayload = action.payload;
        // if (!updatedData) {
        //   return;
        // }
        // state.details = merge({}, state.details, updatedData);
      }

      if (command === 'ok' && okCommand === RetainerTermsCommands.AddProject) {
        state.requestStatusUpdate = 'success';
      }
    });
  },
});

export const retainerTermsActions = {
  ...projectTermsSlice.actions,
  updateRetainerProject,
  addRetainerProject,
};

export default projectTermsSlice.reducer;
