import type { BalancesState } from 'src/store/shared/balances/types';

interface Store {
  balances: BalancesState;
}

export const selectError = (store: Store) => store.balances.error;

export const selectBalancesList = (store: Store) => store.balances.list;
export const selectBalancesSupportedTokens = (store: Store) => store.balances.supportedTokens;
export const selectIsBalancesLoading = (store: Store) => store.balances.requestStatus === 'pending';

export const selectResCloseSmallTokens = (store: Store) => store.balances.resCloseSmallTokens;

export const selectBalancesSelection = (store: Store) => store.balances.selection;
export const selectCreateSnapshotStatus = (store: Store) =>
  store.balances.createBalanceSnapshotStatus;
export const selectCreateWalletSnapshotStatus = (store: Store) =>
  store.balances.createWalletSnapshotStatus;
