import type { RequestStatus } from 'src/store/types';
import type { GenericError } from 'src/store/utils/errors';
import type { LogItem } from 'src/store/shared/types';

export interface RetainerTermsState {
  id?: number;
  details?: RetainerTerms;
  requestStatus: RequestStatus;
  requestStatusUpdate: RequestStatus;
  error: null | GenericError;
  isRetainerEditing: boolean;
  termsEditState: RetainerTermsEditState;
  log: LogItem[];
  logRequestStatus: RequestStatus;
}

export enum RetainerTermsCommands {
  Terms = 'Terms',
  UpdateRetainer = 'UpdateRetainer',
  AddProject = 'AddProject',
  Log = 'Log',
}

// null means that editing is not allowed
export interface RetainerTermsEditState {
  company: boolean | null;
  name: boolean | null;
  started: boolean | null;
  ended: boolean | null;
  exchanges: boolean | null;
  pairs: boolean | null;
  tokens: boolean | null;
}

export interface JsonAddRetainerProjectPayload {
  company: string; // Company name
  name: string; // Loan name
  tokens: string;
  started: string; // string in YYYY-MM-DD format
  ended: string; // string in YYYY-MM-DD format
  exchanges: number[]; // list of exchanges ids
  pairs: string[]; // array of supported pairs, like TOKEN/USDT, TOKEN/BTC, TOKEN/BUSD
  wallets: string;
}

export interface AddRetainerProjectPayload extends Omit<JsonAddRetainerProjectPayload, 'tokens'> {
  tokens: string[];
}

export interface AdditionalRetainerProjectPayload {
  projectID: number;
  baseAmount?: number;
  quoteAmount?: number;
  basePaybackAmount?: number;
  quotePaybackAmount?: number;
  residualPaybackAmount?: number;
}

export interface JsonUpdateRetainerProjectPayload
  extends Partial<JsonAddRetainerProjectPayload>,
    AdditionalRetainerProjectPayload {}

export interface JsonRetainerTerms {
  company: string; // Company name
  name: string; // Loan name
  started: string; // string in YYYY-MM-DD format
  ended: string; // string in YYYY-MM-DD format
  initFunds: number; // used for a new loan form only
  exchanges: number[]; // list of exchanges ids
  pairs: string[]; // array of supported pairs, like TOKEN/USDT, TOKEN/BTC, TOKEN/BUSD
  tokens: string; // Token name
}

export interface RetainerTerms extends Omit<JsonRetainerTerms, 'tokens'> {
  tokens: string[];
  price?: number;
}

export interface JsonRetainerProjectTermsSuccess {
  command: RetainerTermsCommands.Terms;
  details: JsonRetainerTerms;
  id: number;
}

export interface JsonAddRetainerProjectSuccess {
  command: RetainerTermsCommands.AddProject;
  result: number;
}
