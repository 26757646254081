import type { RequestStatus } from 'src/store/types';
import type { GenericError } from 'src/store/utils/errors';
import { LoanCommands } from 'src/store/loans/types';

export interface LoanTermsState {
  id?: number;
  details?: LoanTerms;
  requestStatus: RequestStatus;
  error: null | GenericError;
  isLoanEditing: boolean;
  loanTermsEditState: LoanTermsEditState;
}

export enum LoanTermsCommands {
  Overview = 'Overview',
  LoanLiquidity = LoanCommands.LoanLiquidity,
  Terms = 'Terms',
  UpdateLoan = LoanCommands.UpdateLoan,
}

// null means that editing is not allowed
export interface LoanTermsEditState {
  company: boolean | null;
  name: boolean | null;
  started: boolean | null;
  ended: boolean | null;
  'amounts.baseAmount': boolean | null;
  'amounts.quoteAmount': boolean | null;
  strikePrice: boolean | null;
  'paybackAmounts.baseAmount': boolean | null;
  'paybackAmounts.quoteAmount': boolean | null;
  'paybackAmounts.residualAmount': boolean | null;
  exchanges: boolean | null;
  pairs: boolean | null;
  kpi: boolean | null;
  tokens: boolean | null;
  targetDelta?: number;
}

export interface AddLoanPayload {
  company: string; // Company name
  name: string; // Loan name
  started: string; // string in YYYY-MM-DD format
  ended: string; // string in YYYY-MM-DD format
  amounts: {
    baseAmount: number;
    quoteAmount?: number; // optional
  };
  paybackAmounts: {
    baseAmount: number;
    quoteAmount: number;
    residualAmount?: number; // optional
  };
  exchanges: number[]; // list of exchanges ids
  pairs: string[]; // array of supported pairs, like TOKEN/USDT, TOKEN/BTC, TOKEN/BUSD
  tokens: string[]; // Token name
}

export interface JsonLoanTerms {
  company: string; // Company name
  name: string; // Loan name
  started: string; // string in YYYY-MM-DD format
  ended: string; // string in YYYY-MM-DD format
  initFunds: number; // used for a new loan form only
  amounts: {
    baseAmount: number;
    quoteAmount?: number; // optional
  };
  paybackAmounts: {
    baseAmount: number;
    quoteAmount: number;
    residualAmount?: number; // optional
  };
  exchanges: number[]; // list of exchanges ids
  pairs: string[]; // array of supported pairs, like TOKEN/USDT, TOKEN/BTC, TOKEN/BUSD
  tokens: string; // Token name
  targetDelta?: number;
}

export interface LoanTerms extends Omit<JsonLoanTerms, 'tokens'> {
  tokens: string[];
  price?: number;
}

export interface JsonLoanTermsSuccess {
  command: LoanTermsCommands.Terms;
  details: JsonLoanTerms;
  id: number;
}
