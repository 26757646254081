import type { Middleware } from '@reduxjs/toolkit';

const loggerMiddleware: Middleware = (/* store */) => (next) => (action) => {
  if (import.meta.env.MODE === 'test') {
    return next(action);
  }

  console.debug('dispatching', action);
  const result = next(action);
  // console.log('next state', store.getState());
  return result;
};

export default loggerMiddleware;
