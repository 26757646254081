import React from 'react';

import type { CustomFormats, IntlConfig } from 'react-intl';
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';

// This is optional but highly recommended
// since it prevents memory leak
export const intlCache = createIntlCache();

interface SkyIntlProps {
  children: React.ReactNode;
  locale: string;
  defaultLocale?: string;
}

const intlFormats: CustomFormats = {
  number: {
    engineering: {
      notation: 'engineering',
    },
    scientific: {
      notation: 'scientific',
    },
    highPrecision: {
      notation: 'standard',
      maximumFractionDigits: 12,
    },
    precision: {
      notation: 'standard',
      maximumFractionDigits: 2,
    },
    compactLong: {
      // useGrouping: true,
      notation: 'compact',
      // minimumFractionDigits: 1,
      // maximumFractionDigits: 4,
      // maximumFractionDigits: 12,
      // minimumSignificantDigits: 4,
      // roundingPriority: 'morePrecision',
      compactDisplay: 'short', // e.g. k (short) vs thousands (long)
      trailingZeroDisplay: 'auto',
    },
  },
};

const onIntlError = (err: Error) => {
  // we throw error here to be able to catch it in tests
  // and apply alternative number formatting as needed
  throw err;
};

export const defaultIntlConfig: IntlConfig = {
  locale: 'en',
  defaultLocale: 'en',
  messages: {},
  formats: intlFormats,
  onError: onIntlError,
};

export default function SkyIntlProvider({ children, locale, defaultLocale = 'en' }: SkyIntlProps) {
  const intl = createIntl(
    {
      ...defaultIntlConfig,
      defaultLocale,
      locale,
    },
    intlCache,
  );

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}
