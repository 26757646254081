import omit from 'lodash/omit';
import { createWsAction } from '../utils';

type JsonSerializedArray = string;

interface ArgitrageDetailsListenPayload {
  section: 'arbitrage';
  arbitragesID?: JsonSerializedArray;
}

export const listen = createWsAction<
  ArgitrageDetailsListenPayload & { isInitCall: boolean },
  ArgitrageDetailsListenPayload
>('arbitrage/listen', (data) => ({
  command: 'Listen',
  ...omit(data, 'isInitCall'),
}));
