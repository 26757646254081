import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';
import type { IsoDateString } from 'src/utils/formatting/dates';
import type { JsonExchange } from 'src/store/shared/exchanges/types';

export enum ArbitrageInfoCommands {
  GetArbitrage = 'GetArbitrage',
  ArbitrageInfo = 'ArbitrageInfo',
}

export interface GetArbitrageDataWsPayload {
  projectID?: number;
  loanID?: number;
}

export interface ArbitrageInfoTotal {
  id: number;
  pair: string;
  match: string;
  accountsTotal: number;
  accountsActive: number;
  spread: number;
  buyPrice: number;
  sellPrice: number;
  volume24: number;
  volume: number;
  disbalance: number;
  profit: number;
  status: string;
}

export interface ArbitrageInfoDetail {
  account: string;
  accountID: string;
  exchangeID: number;
  recommendation: string;
  baseAvailable: number;
  baseRecommended: number;
  quoteAvailable: number;
  quoteRecommended: number;
  quoteRatio: number;
  volume24: number;
  volume: number;
  lastTradeTime: IsoDateString;
}

export interface ArbitrageInfoData extends ArbitrageInfoDetail {
  exchange: JsonExchange | undefined;
}

export interface JsonArbitrageInfo {
  total: ArbitrageInfoTotal;
  details: ArbitrageInfoDetail[];
  command: ArbitrageInfoCommands.ArbitrageInfo;
}

export interface ArbitrageInfoState {
  total: ArbitrageInfoTotal | null;
  details: ArbitrageInfoDetail[];
  error: GenericError | null;
  status: RequestStatus;
}
