import { listen as listenLoans } from 'src/store/loans/actions';
import { listen as listenRetainers } from 'src/store/retainers/actions';
import { AccountCommands } from 'src/store/shared/accounts/types';
import { initAccountsSlice } from './factory';

// TODO: check if sections should be updated for retainers and loans

const { reducer, actions } = initAccountsSlice({
  sliceName: 'accounts',
  commands: AccountCommands,
  // extend default reducer
  extraReducers: (builder) => {
    builder.addCase(listenLoans.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      const sections = ['overview', 'balances', 'bots'];
      if (isInitCall && sections.includes(section)) {
        state.requestStatus = 'pending';
      }
    });

    builder.addCase(listenRetainers.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      const sections = ['overview', 'balances', 'bots'];
      if (isInitCall && sections.includes(section)) {
        state.requestStatus = 'pending';
      }
    });
  },
});

export const accountsActions = actions;

export default reducer;
