import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';

interface TradingGroup {
  id: number;
  name: string;
  exchangeID: number;
}

export interface TradingMessageSession {
  session: string;
  ID: string;
}

export interface TradingState {
  error: null | GenericError;
  groupList: null | TradingGroup[];
  groupListStatus: RequestStatus;
  messageStatus: RequestStatus;
  messageLog: Omit<JsonWebMessage, 'command'>[];
  sessionCreateStatus: RequestStatus;
  sessionDetails: TradingMessageSession | null;
}

export enum TradingCommands {
  GroupList = 'GroupList',
  CreateSession = 'CreateSession',
  WebMessage = 'WebMessage',
}

export interface JsonTradingSuccess {
  groups: TradingGroup[];
}

export interface CreateSessionPayload {
  groupId: string;
}

export interface JsonTradingCreateSessionSuccess {
  session: string;
}

export interface JsonWebMessage {
  command: TradingCommands.WebMessage;
  message: string;
  messageID: number;
  session: string;
}

export interface JsonWebMessageRequest extends JsonWebMessage {
  ID: string;
}

export interface WebMessagePayload {
  message: string;
}
